import React from "react";
import H2Text from "../../../components/text/H2Text";
import {Vision} from "./Vision";
import {t} from "i18next";


const Founder: React.FC = () => {

    return (
        <>
            <div className={'mt-lg-3 pt-lg-3 mt-1 pt-1 bg-split-primary'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-9 mt-4 pb-4'}>
                            <H2Text text={t('about.founder.title')}
                                    className={'main-h2-text text-center'}/>
                        </div>
                    </div>
                    <div className={'row  align-items-center'}>
                        <div className={'col-xl-5 col-lg-5 col-md-12 col-12'}>
                            <div>
                                <img src={'/assets/images/about/founder-final.png'} className={'w-100'} alt={'Founder'}/>
                            </div>
                        </div>
                        <div className={'col-md-1'}/>

                        <div className="col-xl-5 col-lg-6 col-md-12 col-12 mt-lg-2  mt-4">
                            <div>
                                <div className={'title-1'}>
                                    {t('about.founder.name')}
                                </div>
                                <div className={'main-text mt-3  mb-lg-5 mb-5'}>
                                    {t('about.founder.p1')}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Vision/>
        </>
    )
}
export {Founder};

import React, {FC} from "react";
import H2Text from "../../../components/text/H2Text";
import {CardVertical} from "../../../components/card/CardVertical";
import {Link} from "react-router-dom";
import {t} from "i18next";


type Props = {
    className?: string
}


const EducationalProgramSection: FC<Props> = ({className}) => {

    return (
        <>
            <div className={'pt-3 pb-3  bg-secondary-color'}>
                <div className={'container  mt-5 pb-5'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-9'}>
                            <H2Text text={className ? t('menu.programs') : t('exhibition.program.title')}
                                    className={'main-h2-text text-center'}/>
                            <div className={'main-text text-center mt-2 mb-2'}>
                                {!className && t('exhibition.program.description')}
                            </div>
                        </div>
                    </div>

                    <div className={'row justify-content-center'}>
                        <div className={`${className ? className : 'col-lg-6  pe-lg-4'} col-md-12 col-12 pt-5`}>
                            <CardVertical h6Text={t('exhibition.program.program1.title')}
                                          description={t('exhibition.program.program1.description')}
                                          className={'bg-light-color'} href={'#'}
                                          image={'/assets/images/exhibition/edu-1.png'}
                            />
                        </div>
                        <div className={`${className ? className : 'col-lg-6   ps-lg-4'}  col-md-12 col-12 pt-5`}>
                            <CardVertical h6Text={t('exhibition.program.program2.title')}
                                          description={t('exhibition.program.program2.description')}
                                          className={'bg-light-color'} href={'#'}
                                          image={'/assets/images/exhibition/edu-2.png'}

                            />
                        </div>

                        <div className={`${className ? className : 'col-lg-6   pe-lg-4'}  col-md-12 col-12 pt-5`}>
                            <CardVertical h6Text={t('exhibition.program.program3.title')}
                                          description={t('exhibition.program.program3.description')}
                                          className={'bg-light-color'} href={'#'}
                                          image={'/assets/images/exhibition/edu-3.png'}
                            />
                        </div>

                        <div className={`${className ? className : 'col-lg-6  ps-lg-4'}  col-md-12 col-12 pt-5 `}>
                            <CardVertical h6Text={t('exhibition.program.program4.title')}
                                          description={t('exhibition.program.program4.description')}
                                          className={'bg-light-color'} href={'#'}
                                          image={'/assets/images/exhibition/edu-4.png'}

                            />

                        </div>
                    </div>

                    {className &&
                        <div className={'row justify-content-center'}>
                            <div className={'col-md-3'}>
                                <Link to={'/exhibitions'} className="btn btn-primary text-secondary-color w-100">
                                    {t('buttons.morePrograms')}
                                </Link>
                            </div>
                        </div>
                    }


                </div>

            </div>
        </>
    )
}
export default EducationalProgramSection;
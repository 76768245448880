import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import LinkPrimary from "../../../components/text/LinkPrimary";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";


type Props = {
    exhibitions?: any
};

const EventsBlock: React.FC<Props> = ({exhibitions}) => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";

    useEffect(() => {

    }, [t])


    return (
        <>
            <div className={'pt-3 pb-3'}>
                <div className={'container'}>
                    {exhibitions && exhibitions.length > 0 && exhibitions.map((exhibition: any, index: number) => (
                        <div className={'row  align-items-center  mt-5 pb-lg-5 pb-2'} key={exhibition.id}>
                            <div
                                className={`col-lg-6 col-md-12 col-12 ${(index + 1) % 2 ? 'order-lg-1 order-1' : ' order-lg-2 order-1'} ${index + 1} `}>
                                <div>
                                    <Link to={'/exhibition/' + exhibition.slug}> <img src={exhibition.main_image} alt={exhibition.name}
                                                                                      className={'w-100 h-400px object-cover'}/></Link>
                                </div>
                            </div>
                            <div
                                className={`col-lg-6 col-md-12 col-12  ${(index + 1) % 2 ? 'order-lg-2 order-1' : 'order-lg-1 order-1'}`}>
                                {/*<CardTwo className={`${(index+1)%2 ? 'pl-lg-70  pl-0' : 'pr-lg-70 pr-0'}`} title={exhibition.name} date={moment(exhibition.start_date).format("MMMM D[, ] YYYY") + ' To ' +  moment(exhibition.end_date).format("MMMM D[, ] YYYY")} description={exhibition.title} link={'/exhibition/' + exhibition.slug} />*/}

                                <div
                                    className={`pt-lg-0 pt-md-5 pt-lg-5 pt-3 ${(index + 1) % 2 ? 'pl-lg-70  pl-0' : 'pr-lg-70 pr-0'}`}>
                                    <div className={'title-1 pt-lg-0 pt-3 mb-3'}>  {'/exhibition/' + exhibition.slug ? (
                                        <Link
                                            to={'/exhibition/' + exhibition.slug}>{exhibition.name}</Link>) : exhibition.name} </div>
                                    {/*<div className={'sm-primary'}>Date</div>*/}
                                    <div className={'text-secondary-d sm-d mb-2'}
                                         dangerouslySetInnerHTML={{__html: moment(exhibition.start_date).locale(currentLanguageCode).format("MMMM D[, ] YYYY") + t('to') + moment(exhibition.end_date).locale(currentLanguageCode).format("MMMM D[, ] YYYY")}}/>
                                    <div className={'main-text mb-4'}
                                         dangerouslySetInnerHTML={{__html: exhibition.title}}/>

                                    {'/exhibition/' + exhibition.slug &&
                                        <LinkPrimary className={'fs-10 lh-14 rd-sm'} text={t('buttons.readMore')}
                                                     icon={`bi bi-arrow-${currentLanguageCode === 'en' ? 'right' : 'left'}`}
                                                     href={'/exhibition/' + exhibition.slug}/>}


                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
export {EventsBlock};
import React from "react";
import H2Text from "../../../components/text/H2Text";
import {CuratorVision} from "./CuratorVision";
import {t} from "i18next";


const Curator: React.FC = () => {

    return (
        <>
            <div className={'mt-lg-3 pt-lg-3 mt-1 pt-1 bg-split-secondary'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-9 mt-4 pb-4'}>
                            <H2Text text={t('about.curator.title')}
                                    className={'main-h2-text text-center'}/>
                        </div>
                    </div>
                    <div className={'row align-items-center '}>
                        <div className={'col-xl-5 col-lg-5  col-md-12 col-12 mt-2 order-xl-3 order-lg-3 order-1'}>
                            <div>
                                <img src={'/assets/images/about/micheala-final.png'} className={'w-100'} alt= {t('about.curator.name')} />
                            </div>
                        </div>
                        <div className={'col-md-1 order-xl-2 order-lg-2'}/>
                        <div
                            className="col-xl-5 col-lg-6 col-md-12 col-12  mt-lg-2  mt-4 order-xl-1 order-lg-1 order-3">
                            <div>
                                <div className={'title-1'}>
                                    {t('about.curator.name')}
                                </div>
                                <div className={'main-text mt-3 mb-lg-5 mb-5'}>
                                    {t('about.curator.p1')}   </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <CuratorVision/>
        </>
    )
}
export {Curator};

import React from "react";


type Props = {
    className?: string;
    h6Text?: string;
    description?: any
    date?: any
    classText?: any
    link?: any
};

const CardOne: React.FC<Props> = ({className, h6Text, description, date, classText, link}) => {

    return (
        <div className={`card-one pt-lg-4 pt-1 ${className}`}>
            <h6 className={classText}>{h6Text}</h6>
            {description && <p dangerouslySetInnerHTML={{__html: description}}/>}
            {date && <div className={'text-secondary-d mb-2'} dangerouslySetInnerHTML={{__html: date}}/>}

        </div>
    )
}
export {CardOne};

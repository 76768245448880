import React, {FC} from "react";

type Props = {
    text?: string
    className?: string

}


const H2Text: FC<Props> = ({text, className}) => {
    return (
        <>
            <h2 className={className}>{text}</h2>
        </>
    )
}
export default H2Text;
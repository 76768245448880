import React, {FC, useEffect, useState} from "react";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {eventIndex} from "../core/requests";
import EventCard from "./EventCard";

type Props = {
    sections?: any;
    eventsSection?: any
    start?: any
    end?: any
    futureEvents?: boolean
    past?: any
    flag?: any

};

const Calendar: FC<Props> = ({sections, eventsSection, start, end, futureEvents, past, flag}) => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [currentMonth] = useState<Date>(new Date());
    const [selectedSection, setSelectedSection] = useState<{ id: number | string, name: string }>({id: 0, name: ''});
    const [events, setEvents] = useState<any[]>(eventsSection);
    const [pastEvents, setPastEvents] = useState<any[]>(past);
    const [groupedEvents, setGroupedEvents] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingSection, setLoadingSection] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [hasFutureEvents, setHasFutureEvets] = useState<boolean | undefined>(futureEvents);

    useEffect(() => {
        getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonth, selectedSection, currentPage, startDate]); // Include currentPage in dependencies

    useEffect(() => {
        const getStartDateOfMonth = () => {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth(), 1);
        };

        const getEndDateOfMonth = () => {
            const today = new Date();
            return new Date(today.getFullYear(), today.getMonth() + 1, 0);
        };

        setStartDate(getStartDateOfMonth());
        setEndDate(getEndDateOfMonth());
    }, []);

    const goToPreviousMonth = () => {
        setLoadingSection(true)
        setCurrentPage(1)
        setStartDate((prevStartDate) => {
            const prevMonth = new Date(
                prevStartDate!.getFullYear(),
                prevStartDate!.getMonth() - 1,
                1
            );
            return prevMonth;
        });
        setEndDate((prevEndDate) => {
            const prevMonth = new Date(
                prevEndDate!.getFullYear(),
                prevEndDate!.getMonth(),
                0
            );
            return prevMonth;
        });

    };

    const goToNextMonth = () => {
        setLoadingSection(true)
        setStartDate((prevStartDate) => {
            const nextMonth = new Date(
                prevStartDate!.getFullYear(),
                prevStartDate!.getMonth() + 1,
                1
            );
            return nextMonth;
        });
        setEndDate((prevEndDate) => {
            const nextMonth = new Date(
                prevEndDate!.getFullYear(),
                prevEndDate!.getMonth() + 2,
                0
            );
            return nextMonth;
        });
        setCurrentPage(1)
    };

    const getEvents = async () => {
        eventIndex(startDate?.getMonth(), startDate?.getFullYear(), selectedSection.id, currentPage, currentLanguageCode).then((response) => {
            const isArabic = currentLanguageCode === 'ar';

            const groupedEvents = response.data.events.reduce((acc: { [key: string]: any }, event: any) => {
                const date = new Date(event.date);
                const month = isArabic ? date.toLocaleString(currentLanguageCode, {
                    month: 'long',
                    localeMatcher: 'lookup',
                    timeZone: 'UTC'
                }).replace(" ", "م") : date.toLocaleString(currentLanguageCode, {month: 'long'});
                const monthYear = `${month} ${date.getFullYear()}`;

                if (!acc[monthYear]) {
                    acc[monthYear] = [];
                }

                acc[monthYear].push(event);
                return acc;
            }, {});

            if (currentPage === 1) {
                setGroupedEvents(groupedEvents);
                // If it's the first page, set events directly from the API response
                setEvents(response.data.events);
            } else {
                // If it's not the first page, append new events to existing ones
                setGroupedEvents((prevEvents) => {
                    // Create a copy of the previous state
                    const updatedEvents: any = {...prevEvents};

                    // Merge new events into the copy
                    for (const monthYear in groupedEvents) {
                        if (groupedEvents.hasOwnProperty(monthYear)) {
                            if (!updatedEvents[monthYear]) {
                                updatedEvents[monthYear] = [];
                            }
                            updatedEvents[monthYear] = [...updatedEvents[monthYear], ...groupedEvents[monthYear]];
                        }
                    }

                    // Return the updated state
                    return updatedEvents;
                });
            }
            setPastEvents(response.data.pastEvents);
            setTotalPages(response.data.totalPages);
            setHasFutureEvets(response.data.futureEvents);
            setLoading(false)
            setLoadingSection(false)
        });

    };

    const handleSectionClick = (section: any) => {
        setLoadingSection(true)
        setSelectedSection(section);
        setCurrentPage(1)
    };

    const handleLoadMore = () => {
        setLoading(true)
        setCurrentPage((prevPage) => prevPage + 1);
    };



    return (
        <>

            {flag !== undefined ?
                <div className={"mt-lg-5 pt-lg-2 mt-4 pt-1"}>
                    <div className={"container"}>

                        <div className={"row justify-content-center"}>
                            <div className={"col-md-1 col-2 text-center"}>
                                {flag !== 1 ? (
                                    (moment(startDate).isSameOrAfter(moment(new Date()), 'month')) ? (
                                        <span className={"cursor-pointer"} onClick={goToPreviousMonth}>
            {currentLanguageCode === "en" ? (
                <img src={"/assets/images/events/arrow-prev.png"} alt={'Arrow Prev'}/>
            ) : (
                <img src={"/assets/images/events/arrow-next.png"} alt={'Arrow Next'}/>
            )}
        </span>
                                    ) : (
                                        <span className={"cursor-pointer"}>
            {currentLanguageCode === "en" ? (
                <img src={"/assets/images/events/arrow-prev.png"} alt={'Arrow Prev'}/>
            ) : (
                <img src={"/assets/images/events/arrow-next.png"} alt={'Arrow Prev'}/>
            )}
        </span>
                                    )
                                ) : null}


                            </div>

                            {flag === 1 || (moment(startDate).isBefore(moment(new Date()), 'month')) ?
                                <div className={"col-md-3 col-8 text-center"}>
                                    <div className={"title-1 lt-sp-ms "}>
                                        {t('events.pastEvents')}
                                    </div>

                                </div>

                                :
                                <div className={"col-md-3 col-8 text-center"}>
                                    <div className={"title-1 lt-sp-ms "}>
                                        {moment(startDate)
                                            .locale(currentLanguageCode)
                                            .format("MMMM")}
                                    </div>
                                    <p className={`calendar-date ${currentLanguageCode === 'ar' && 'mt-1'}`}>
                                        {moment(startDate).locale(currentLanguageCode).format("D MMMM YYYY")}{" "}
                                        {t("to")}{" "}
                                        {moment(endDate).locale(currentLanguageCode).format("D MMMM YYYY")}
                                    </p>
                                </div>
                            }


                            <div className={"col-md-1 col-2 text-center"}>

                                {flag !== 1 ? (
                                    hasFutureEvents ? (
                                        <span className={"cursor-pointer"} onClick={goToNextMonth}>
                                    <img alt={'Arrow'}
                                         src={currentLanguageCode === "en" ? "/assets/images/events/arrow-next.png" : "/assets/images/events/arrow-prev.png"}/>
                                </span>
                                    ) : (
                                        <span className={"disabled"}>
                                    <img alt={'Arrow'}
                                         src={currentLanguageCode === "en" ? "/assets/images/events/arrow-next.png" : "/assets/images/events/arrow-prev.png"}/>
                                </span>
                                    )
                                ) : null}

                            </div>
                        </div>

                        <div className={"row justify-content-center mt-4"}>
                            <div className={"col-lg-12 text-center"}>
                                <ul className={"d-lg-inline-flex d-block sections-list justify-content-between"}>
                                    <li className={`sections-title cursor-pointer ${selectedSection.id === 0 ? "active" : ""}`}
                                        onClick={() => handleSectionClick({id: 0, name: t('events.allPrograms')})}
                                        key={'all-programs'}>
                                        {t('events.allPrograms')}
                                    </li>
                                    {sections &&
                                        sections.length > 0 &&
                                        sections.map((section: any) => (
                                            <li className={`sections-title cursor-pointer ${selectedSection.id === section.id ? "active" : ""}`}
                                                onClick={() => handleSectionClick({
                                                    id: section.id, name: section.name
                                                })} key={section.id}>
                                                {section.name}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>

                        {groupedEvents && Object.entries(groupedEvents).length > 0 &&
                            <div className={"row justify-content-center mt-4 pt-2 pb-2"}>
                                {Object.entries(groupedEvents).map(([monthYear, events]) => (
                                    <div className={"col-xl-9 col-lg-9 col-md-12 col-12 p-0"} key={monthYear}>
                                        {(flag === 1 || (moment(startDate).isBefore(moment(new Date()), 'month'))) &&
                                            <div className={'text-secondary-d sm-d text-primary-color mb-2'}>
                                                {monthYear}
                                            </div>
                                        }
                                        {events.map((event: any) => (
                                            event.image && (
                                                <EventCard   colClass={'col-xl-12 col-lg-12 col-md-12 col-12'} event={event} index={event.id} key={event.id}

                                                           isPast={(moment(startDate).isBefore(moment(new Date()), 'month'))}/>

                                            )
                                        ))}
                                    </div>


                                ))}

                            </div>
                        }
                        {loadingSection ? (
                                <div className={'row justify-content-center'}>
                                    <div className={'col-12 text-center mb-4'}>
                                        <div className="text-secondary-color w-100">
                                        <span className="indicator-progress" style={{display: "block"}}>
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            ) :
                            Object.entries(groupedEvents).length === 0 && selectedSection.id !== 0 && (!pastEvents || (pastEvents && pastEvents.length === 0)) &&
                            <div className={'row justify-content-center mt-3 mb-5'}>
                                <div className={'col-lg-9 col-md-12 text-center'}>
                                    <p className={'sections-title text-normal'}> {t('events.noEvents', {section: selectedSection.name})}</p>
                                </div>
                            </div>


                        }

                        <div className={`${events && events.length > 0 && 'mt-3 mb-3 pb-3'}`}>
                            {currentPage < totalPages && (
                                <div className={"row justify-content-center mt-2 mb-2 pb-2"}>
                                    <div className={"col-md-2"}>


                                        {!loading && (
                                            <button onClick={handleLoadMore}
                                                    className="btn btn-primary text-secondary-color w-100">

                                                {t('buttons.loadMore')}

                                            </button>
                                        )}
                                        {loading && (
                                            <button className="btn btn-primary text-secondary-color w-100">
                                                    <span
                                                        className="indicator-progress"
                                                        style={{display: "block"}}
                                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                            </button>
                                        )}


                                    </div>
                                </div>
                            )}
                        </div>

                        {(moment(startDate).isSameOrAfter(moment(new Date()), 'month')) &&
                            pastEvents && pastEvents.length > 0 && (
                                <div className={"row justify-content-center mt-4 pb-4"}>
                                    <div className={"col-xl-9 col-lg-9 col-md-12 col-12 p-0"}>
                                        <div className={'text-secondary-d sm-d text-primary-color mb-2'}>
                                            {t("events.pastEvents")}
                                        </div>
                                    </div>
                                    {pastEvents.map((event: any) => (
                                        event.image && (
                                            <EventCard colClass={'col-xl-9 col-lg-9 col-md-12 col-12'}  event={event} index={event.id} isPast={true}/>
                                        )
                                    ))}
                                </div>)
                        }
                    </div>
                </div>
                :
                <div className={'row justify-content-center'}>
                    <div className={'col-12 text-center mt-5 mb-5'}>
                        <div className="text-secondary-color w-100">
                                        <span className="indicator-progress" style={{display: "block"}}>
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Calendar;

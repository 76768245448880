import React, {FC} from "react";

type Props = {
    text?: string
    className?: string

}


const H1Text: FC<Props> = ({text, className}) => {
    return (
        <>
            <h1 className={className}>{text}</h1>
        </>
    )
}
export default H1Text;
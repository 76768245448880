import React, {FC, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import Slider from "react-slick";
import {toAbsoluteUrl} from "../../helpers/AssetHelpers";
import Cookies from "js-cookie";

type Props = {
    images: any
}
const CarouselItems: FC<Props> = ({images}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const sliderRef = useRef<Slider>(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const settings = {
        dots: images.length !== 1,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,

    };


    const handleImageClick = (image: any, index: number) => {
        setSelectedImage(image);
        setSelectedIndex(index);
        setShowModal(true);
    };
    const handlePrevClick = () => {
        const newIndex = (selectedIndex - 1 + images.length) % images.length;
        setSelectedImage(images[newIndex]);
        setSelectedIndex(newIndex);
    };

    const handleNextClick = () => {
        const newIndex = (selectedIndex + 1) % images.length;
        setSelectedImage(images[newIndex]);
        setSelectedIndex(newIndex);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (
        <div className={'pb-4'}>
            {images.length > 1 ?
                <Slider {...settings} className={'dark-carousel text-center'} ref={sliderRef}>
                    {images.map((item: any, index: number) => {
                            return (
                                <>
                                    <div className="cursor-pointer" onClick={() => handleImageClick(item, index)}>
                                        <img
                                            className="d-block w-100 "
                                            src={toAbsoluteUrl(item.image)}
                                            alt="Event"
                                        />
                                        <div className={'maximize-icon'}>
                                            <img src={'/assets/images/icons/maximize.png'} className={'w-100'}
                                                 alt={'icon'}/>
                                        </div>

                                        <p className={`main-text pt-3 ${currentLanguageCode === 'en' ? 'text-start' : 'text-end'}`}
                                           dangerouslySetInnerHTML={{__html: item.description}}/>
                                    </div>

                                </>

                            )
                        }
                    )}
                </Slider>
                :
                images && images.length > 0 ?
                    <div className={''}>
                        <div className={'p-4'}>
                            <div className={'  position-relative'}>
                                <div className={'maximize-icon'}>
                                    <img src={'/assets/images/icons/maximize.png'} className={'w-100'} alt={'icon'}/>
                                </div>
                                <img src={toAbsoluteUrl(images[0].image)} className={'cursor-pointer w-100'}
                                     alt={'Event'}
                                     onClick={() => handleImageClick(images[0], images[0].id)}/>
                            </div>
                            <div className={'mt-2'}>
                                <p className={'main-text opc text-start'}
                                   dangerouslySetInnerHTML={{__html: images[0].description}}/>

                            </div>
                        </div>
                    </div>
                    : null
            }

            {selectedImage &&
                <Modal className={'event-image-popup'} centered={true} show={showModal}
                       onHide={handleCloseModal} size={'lg'}>
                    <Modal.Body>
                        <div className={'row justify-content-center mt-2  position-relative'}>
                            <div className={'col-md-11'}>
                                <div className={''}>
                                    <img src={toAbsoluteUrl(selectedImage.image)} className={' w-100'} alt={'Highlights'}/>
                                </div>
                                {images && images.length > 1 &&
                                    <>
                                        <div className={'left-arr'}>
                                            <div onClick={handlePrevClick} className={'cursor-pointer'}>
                                                <i className="fa-solid fa-chevron-left"></i>
                                            </div>
                                        </div>
                                        <div className={'right-arr'}>
                                            <div onClick={handleNextClick} className={'cursor-pointer'}>
                                                <i className="fa-solid fa-chevron-right"></i>
                                            </div>
                                        </div>
                                    </>}


                                <div className={'mt-3'}>
                                    <p className={'main-text opc '}
                                       dangerouslySetInnerHTML={{__html: selectedImage.description}}/>

                                </div>
                            </div>
                        </div>

                    </Modal.Body>

                </Modal>
            }

        </div>
    )
}
export default CarouselItems;
import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import H2Text from "../../../components/text/H2Text";
import Select from "react-select";
import {contactUs} from "../../../core/requests";
import {t} from "i18next";

const contactSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("First name is required"),


    message: Yup.string()
        .required("Message is required"),

    phoneNumber: Yup.number()
        .required("Phone number is required"),
    category_id: Yup.number().required("Category is required")
});

const initialValues = {
    email: "",
    name: "",
    message: "",
    phoneNumber: "",
    category_id: "",
};

type Props = {
    categories?: any
}

const ContactForm: FC<Props> = ({categories}) => {

    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const customStyles = {
        option: (provided: any) => ({
            ...provided,
            padding: '2px 12px', // Adjust padding as needed
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            padding: '0px', // Adjust padding as needed
        }),
    };

    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);

                const {data} = await contactUs(values);

                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);
                        // resetForm()
                    }, 2500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className={'container pt-3 mb-5 pb-5'}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <H2Text text={t('visit.form.title')}
                                className={'main-h2-text lh-30'}/>
                        {/*<PText text={'For more information fill the below form!'} className={'subtitle'} />*/}

                    </div>

                    <div className={'col-md-10'}>
                        {isActive && (
                            <div className={``}>
                                <div className="alert alert-success alert-dismissible fade show  ">
                                    Successfully Done!
                                </div>
                            </div>
                        )}
                        <form className="form w-100 " onSubmit={formik.handleSubmit} noValidate>
                            <div className={' form-group row '}>
                                <div className={'col-lg-6 col-md-12 mt-2 mb-2'}>
                                    <label className={'main-label'}>{t('visit.form.name')}:</label>
                                    <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                        "form-control main-input",
                                        {"is-invalid": formik.touched.name && formik.errors.name,},
                                        {"is-valid": formik.touched.name && !formik.errors.name,}
                                    )} placeholder={t('visit.form.namePlaceholder')}/>
                                </div>
                                <div className={'col-lg-6 col-md-12 mt-2 mb-2 '}>
                                    <label className={'main-label'}>{t('visit.form.email')} :</label>
                                    <input type={'email'}  {...formik.getFieldProps("email")}
                                           placeholder={t('visit.form.emailPlaceholder')}
                                           className={clsx("form-control main-input", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                </div>

                            </div>
                            <div className={' form-group row'}>
                                <div className={'col-lg-6 col-md-12 mt-2 mb-2'}>
                                    <label className={'main-label'}>{t('visit.form.phoneNumber')}:</label>
                                    <input type={'number'}  {...formik.getFieldProps("phoneNumber")}
                                           className={clsx("form-control main-input", {"is-invalid": formik.touched.phoneNumber && formik.errors.phoneNumber,}, {"is-valid": formik.touched.phoneNumber && !formik.errors.phoneNumber,})}
                                           placeholder={t('visit.form.phoneNumberPlaceholder')}/>
                                </div>
                                <div className={'col-lg-6 col-md-12 mt-2 mb-2'}>
                                    <label className={'main-label'}>{t('visit.form.subject')}:</label>
                                    {categories && categories.length > 0 &&
                                        <Select
                                            styles={customStyles}
                                            className={clsx(
                                                "w-100 main-input",
                                                {"invalid-select": formik.touched.category_id && formik.errors.category_id,},
                                                {"valid-select": formik.touched.category_id && !formik.errors.category_id,}
                                            )}
                                            closeMenuOnSelect={false}
                                            placeholder={t('visit.form.subjectPlaceholder')}
                                            options={categories.map((e: {
                                                name: any;
                                                id: any
                                            }) => ({
                                                label: e.name,
                                                value: e.id
                                            }))}
                                            isSearchable={true}
                                            onChange={(newValue: any) =>
                                                formik.setFieldValue('category_id', newValue.value)
                                            }
                                        />

                                    }

                                </div>
                            </div>
                            <div className={'form-group row'}>
                                <div className={'col-md-12  mt-2 mb-2'}>
                                    <label className={'main-label'}>{t('visit.form.description')}:</label>
                                    <textarea
                                        placeholder={t('visit.form.descriptionPlaceholder')} {...formik.getFieldProps("message")}
                                        className={clsx("form-control main-input  h-185px ", {"is-invalid": formik.touched.message && formik.errors.message,}, {"is-valid": formik.touched.message && !formik.errors.message,})}/>
                                </div>
                            </div>
                            <div className={'row mt-lg-3 mt-3'}>
                                <div className={'col-lg-3'}>
                                    <button className="btn btn-primary text-secondary-color w-100 "
                                            disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                        {!loading && (
                                            <span className="indicator-label">
                                                {t('visit.form.button')}
                                </span>
                                        )}
                                        {loading && (
                                            <span className="indicator-progress" style={{display: "block"}}><span
                                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>

        </>
    )
};

export default ContactForm;
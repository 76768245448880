import axios from 'axios'
import {API_URL} from '../../../helpers/crud-helper/consts'

const EVENT_URL = `${API_URL}/event`

const eventIndex = (month: any, year: any, section: any, page: any, currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${EVENT_URL}?language=${currentLanguageCode}&month=${month}&section=${section}&year=${year}&page=${page}`)
        .then((response) => response)
        .catch((response) => response)
}
const eventShow = (slug: any, currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${EVENT_URL}/${slug}?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response)
}
const bookEvent = (id: any, value: any): Promise<any> => {
    return axios
        .post(`${EVENT_URL}/${id}/book`, value)
        .then((response) => response)
}

export {eventIndex, eventShow, bookEvent}

import React from "react";


type Props = {
    className?: string;

};

const Separator: React.FC<Props> = ({className}) => {

    return (
        <div className={'container'}>
            <hr className={`${className}`}/>

        </div>
    )
}
export {Separator};

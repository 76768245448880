import React, { FC, useState } from 'react';
import moment from "moment";
import { PopUp } from "../../../components/modal/Modal";
import {formatTimeRange} from "../../../helpers/Utilities";
import {ClosedDay} from "../../../helpers/crud-helper/models";
import {t} from "i18next";



interface Props {
    closedDays?: ClosedDay[];
    currentLanguageCode?: string;
}



const formatClosedDays = (closedDays: ClosedDay[], currentLanguageCode: string) => {
    return closedDays.slice(0,2).map((closedDay, index) => {
        const formattedTime = formatTimeRange(closedDay.start_time, closedDay.end_time, currentLanguageCode);
        const isLastDay = index === closedDays.length - 1;

        return (
            <span key={closedDay.id}>
                {moment(closedDay.id).locale(currentLanguageCode).format("dddd MMMM D, YYYY")}
                {closedDay.start_time || closedDay.end_time ? (
                    <> From {formattedTime} </>
                ) : (
                    ' Closed All Day'
                )}
                {!isLastDay && ', '}
            </span>
        );
    });
};

const Strip: FC<Props> = ({ closedDays = [], currentLanguageCode = 'en' }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const formatTimeRange = (startTime: string, endTime: string, currentLanguageCode: string) => {
        const formattedStartTime = moment(startTime, 'HH:mm').locale(currentLanguageCode).format('hh:mm A');
        const formattedEndTime = moment(endTime, 'HH:mm').locale(currentLanguageCode).format('hh:mm A');
        return `${formattedStartTime} to ${formattedEndTime}`;
    };
    const formatClosedDaysAsString = (closedDays: ClosedDay[], currentLanguageCode: string) => {

        return closedDays.slice(0, 1).map((closedDay: any) => {

            const hasTimeRange = closedDay.start_time && closedDay.end_time;
            const formattedTime = hasTimeRange ? formatTimeRange(closedDay.start_time!, closedDay.end_time!, currentLanguageCode) : '';

            let dayInfo = `${closedDay.end_date ? 'From' : ''} ${moment(closedDay.start_date).locale(currentLanguageCode).format("dddd D, MM, YYYY")}`;

            if (closedDay.end_date) {
                dayInfo += ` to ${moment(closedDay.end_date).locale(currentLanguageCode).format("dddd D, MM, YYYY")}`;
            }

            if (hasTimeRange) {
                dayInfo += ` (From ${formattedTime})`;
            } else if (closedDay.start_time || closedDay.end_time) {
                dayInfo += ' (';
                dayInfo += closedDay.start_time ? `From ${formattedTime}` : `To ${formattedTime}`;
                dayInfo += ')';
            } else {
                dayInfo += ' (Closed all day)';
            }

            return dayInfo;
        }).join(', ');
    };

// Inside PopUp component
    const formattedClosedDays = formatClosedDaysAsString(closedDays, currentLanguageCode);
    // const closedDaysText = formatClosedDays(closedDays, currentLanguageCode);
    // const shouldShowEllipsis = closedDays.length > 3;

    return (
        <>
            <div className="strip-alert alert alert-secondary text-center justify-content-center alert-dismissible fade show z-index-9999 w-100 bg-primary-color main-text secondary mb-0"
                 role="alert"  >

                {closedDays.map((closedDay: any) => (
                    <span className={'main-text secondary me-2'} key={closedDay.id} dangerouslySetInnerHTML={{__html: closedDay.reason}} />
                ))}

                <span className="main-text secondary text-decoration-underline cursor-pointer ms-1" onClick={openModal}> Learn More </span>
            </div>

            <PopUp isModalOpen={isModalOpen} closeModal={closeModal} closedDays={closedDays} />
        </>
    );
};

export { Strip };

import React, {FC, useEffect, useState} from "react";
import {CardHero} from "../../components/card/CardHero";
import {SocialMeta} from "../../layout/base/components/social-meta";
import Cookies from "js-cookie";
import Calendar from "./components/Calendar";
import {eventIndex} from "./core/requests";
import EducationalProgramSection from "../exhibition/components/EducationalProgramSection";


const EventIndex: FC = () => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [sections, setSections] = useState<any[]>([]);
    const [startDate] = useState<Date | null>(new Date());
    const [endDate] = useState<Date | null>(null);
    const [events, setEvents] = useState<any[]>([]);
    const [page, setPage] = useState<any>()
    const [hasFutureEvents, setHasFutureEvets] = useState<boolean | undefined>();
    const [pastEvents, setPastEvents] = useState<any[]>([]);
    const [flag, setFlag] = useState<any>();

    useEffect(() => {
        getEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const getEvents = async () => {
        eventIndex(startDate?.getMonth(), startDate?.getFullYear(), 0, 1, currentLanguageCode).then((response) => {

            setHasFutureEvets(response.data.futureEvents);
            setSections(response.data.sections);
            setPastEvents(response.data.pastEvents);

            setPage(response.data.page)

            setEvents(response.data.events);
            setFlag(response.data.flag)

        });

    };

    return (
        <>
            <SocialMeta title={page && page.meta_title && page.meta_title}
                        description={page && page.meta_description && page.meta_description}/>
            <CardHero image={'/assets/images/events/banner-events.jpg'} className={''}
                      title={''}/>
            {events && <Calendar eventsSection={events} sections={sections} start={startDate} end={endDate} flag={flag}
                                 futureEvents={hasFutureEvents} past={pastEvents}/>}

            <EducationalProgramSection className={'col-lg-3'}/>
        </>
    );
};

export default EventIndex;
import React from "react";
import {t} from "i18next";


const CuratorVision: React.FC = () => {

    return (
        <>
            <div className={'bg-secondary-color'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-lg-5 col-md-12 col-12'}>
                            <div className={'title-1 mt-5'}>
                                {t('about.curator.vision.title')}
                            </div>
                        </div>
                    </div>
                    <div className={'row pb-5'}>
                        <div className={'col-lg-5 col-md-12 col-12 mt-3'}>
                            <div className={'main-text '}>
                                <p>   {t('about.curator.vision.p1')} </p>
                                <p> {t('about.curator.vision.p2')} </p>

                                <p>{t('about.curator.vision.p3')} </p>


                            </div>
                        </div>
                        <div className={'col-md-1'}/>
                        <div className={'col-lg-5 col-md-12 col-12 mt-3'}>
                            <div className={'main-text'}>
                                <p> {t('about.curator.vision.p4')} </p>

                                <p>{t('about.curator.vision.p5')} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export {CuratorVision};

import React, {FC, useEffect, useState} from "react";
import {CardHero} from "../../components/card/CardHero";
import Exhibitions from "./components/Exhibitions";
import EducationalProgramSection from "./components/EducationalProgramSection";
import MoreInfo from "./components/MoreInfo";
import {getEventsExhibitions} from "../../core/requests";
import {SocialMeta} from "../../layout/base/components/social-meta";
import Cookies from "js-cookie";
import EventCard from "../event/components/EventCard";
import {PrimaryButton} from "../../components/button/PrimaryButton";
import {t} from "i18next";

type Props = {
    exhibitions: any
}

const ExhibitionIndex: FC<Props> = ({exhibitions}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";

    const [page, setPage] = useState<any>();
    const [events, setEvents] = useState<any>();

    useEffect(() => {
        getEventsExhibitions(currentLanguageCode).then((response) => {
            setPage(response.data.page);
            setEvents(response.data.events)
        });
    }, [currentLanguageCode]);

    return (
        <>
            <SocialMeta title={page ? page.meta_title : 'Unveiling Art at BFAF: Explore Upcoming & Past Exhibitions'}
                        description={page ? page.meta_description : 'Discover upcoming and past exhibitions at the Bassam Freiha Art Foundation (BFAF) in Abu Dhabi. Delve into diverse art forms and perspectives through BFAF\'s curated exhibitions.'}/>
            <CardHero image={'/assets/images/exhibition/whats-on-banner.png'}
                      className={'d-lg-block d-md-block d-none'}/>
            <Exhibitions exhibitions={exhibitions}/>
            <EducationalProgramSection/>
            {
                events && events.length > 0 && (
                    <>
                        <div className={"row justify-content-center mt-4 pb-3"}>
                            <div className={"col-xl-9 col-lg-12 col-md-12 col-12 p-0 mt-5"}>
                                <div className={'main-h2-text text-center mb-4'}>
                                    {t('events.latestEvents')}
                                </div>
                            </div>
                            {events.map((event: any) => (
                                event.image && (
                                    <EventCard colClass={'col-xl-9 col-lg-12 col-md-12 col-12'} key={event.id} event={event} index={event.id} isPast={true}/>
                                )
                            ))}

                        </div>
                        <div className={"row justify-content-center  pb-5"}>
                            <div className={"col-xl-2 col-lg-2 col-md-12 col-12 p-0 mt-3"}>
                                <PrimaryButton href={`/whats-on`}
                                               className={'btn btn-primary text-secondary-color w-100'}
                                               title={t('buttons.moreEvents')}/>
                            </div>
                        </div>
                    </>)
            }
            <MoreInfo className={'bg-secondary-color'}/>


        </>
    );
};

export default ExhibitionIndex;
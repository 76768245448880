import moment from "moment/moment";
import {PageContent} from "../models/PageContent";
import {ClosedDay} from "./crud-helper/models";

export const formatTimeRange = (startTime: string | null, endTime: string | null, locale: string) => {
    const formattedComponents = [];

    if (startTime) {
        formattedComponents.push(moment(startTime, 'HH:mm:ss').locale(locale).format("h:mm A"));
    }

    if (startTime && endTime) {
        formattedComponents.push(' to ');
    }

    if (endTime) {
        formattedComponents.push(moment(endTime, 'HH:mm:ss').locale(locale).format("h:mm A"));
    }

    return formattedComponents.join('');
};

export const filterPageContentBySectionType = (pageContent: PageContent[], sectionType: string) => {
    return pageContent.find((content: PageContent) => content.section_type === sectionType);
};

export const formatClosedDaysAsString = (closedDays: ClosedDay[], currentLanguageCode: string) => {

    return closedDays.map((closedDay:any) => {

        const hasTimeRange = closedDay.start_time && closedDay.end_time;
        const formattedTime = hasTimeRange ? formatTimeRange(closedDay.start_time!, closedDay.end_time!, currentLanguageCode) : '';

        // const formattedTime = formatTimeRange(closedDay.start_time, closedDay.end_time, currentLanguageCode);
        // const hasTimeRange = closedDay.start_time && closedDay.end_time;

        let dayInfo = `${closedDay.end_date ? 'From' : ''}  ${moment(closedDay.start_date).locale(currentLanguageCode).format("dddd D, MM, YYYY")}`;
        if (closedDay.end_date) {
            dayInfo += ` to ${moment(closedDay.end_date).locale(currentLanguageCode).format("dddd D, MM, YYYY")}`;
        }
        if (hasTimeRange) {
            dayInfo += ` (From ${formattedTime})`;
        } else if (closedDay.start_time || closedDay.end_time) {
            dayInfo += ' (';
            dayInfo += closedDay.start_time ? `From ${formattedTime}` : `To ${formattedTime}`;
            dayInfo += ')';
        } else {
            dayInfo += ' (Closed all day)';
        }

        return dayInfo;
    }).join(', ');
};
import React, {FC, useEffect, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import moment from "moment";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import {bookEvent} from "../core/requests";

type Props = {
    event?: any
}


const bookSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("Name is required"),


    seat_number: Yup.string()
        .required("Last name is required"),


});
const BookForm: FC<Props> = ({event}) => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [result, setResult] = useState<any>();
    const [seats, setSeats] = useState<any>(1);

    const handleDecreaseSeats = () => {
        if (seats > 1) {
            setSeats(seats - 1);
        }

    };

    const handleIncreaseSeats = () => {
        setSeats(seats + 1);
    };
    useEffect(() => {
        formik.setFieldValue('seat_number', seats)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seats]);
    const initialValues = {
        email: "",
        name: "",
        seat_number: "",
        agree: false,


    };
    const formik = useFormik({
        initialValues,
        validationSchema: bookSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);
                const {data} = await bookEvent(event.id, values);

                if (data.success === 1) {
                    setLoading(false);
                    setIsActive(true);
                    setResult(data.eventBooking)
                    formik.resetForm();
                    // setTimeout(function () {
                    //     setIsActive(false);
                    //     // resetForm()
                    // }, 4500);
                } else {
                    setResult(data.seatsAvailable)
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className={'col-lg-5 col-md-12 col-12  mt-lg-5 mt-4 '}>
                <div className={'bg-secondary-color'}>
                    {isActive ? (
                            <div className={'p-4'}>
                                <div className={`row justify-content-center`}>
                                    <div className={'col-3'}>
                                        <img src={'/assets/images/events/check.png'} className={'w-100'} alt={'BFAF - Events'}/>

                                    </div>
                                    <div className={'text-center col-12'}>
                                        <div className={'text-center pt-3'}>
                                            <div className={'main-h2-text primary'}>{t('events.bookingConfirmed')}</div>
                                        </div>
                                        <div className={'main-text opc '}>
                                            {t('events.form.bookingNumber')}: BFAF-{result}
                                        </div>
                                        <div className={'text-secondary-d sm-d  mt-3'}>
                                            {moment(event.date).locale(currentLanguageCode).format("MMMM D, YYYY - h:mm A")}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                        :
                        event.seats_available > 0 ?
                            <div className={'p-4'}>
                                <div className={' pt-3'}>
                                    <div className={'main-h2-text primary'}>{t('events.form.title')}
                                    </div>
                                </div>
                                {result &&
                                    <div className={'alert alert-danger'}>
                                        Booking exceeds capacity. Please update
                                    </div>
                                }

                                <div className={'text-secondary-d sm-d'}>
                                    {moment(event.date).locale(currentLanguageCode).format("MMMM D, YYYY - h:mm A")}
                                </div>

                                <div className={' '}>
                                    <form className="form justify-content-center w-100 " onSubmit={formik.handleSubmit}
                                          noValidate>
                                        <div className={' form-group row '}>
                                            <div className={'col-lg-12 col-md-12 mt-2 mb-2'}>
                                                <label className={'main-label'}>{t('events.form.name')}:</label>
                                                <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                                    "form-control main-input",
                                                    {"is-invalid": formik.touched.name && formik.errors.name,},
                                                    {"is-valid": formik.touched.name && !formik.errors.name,}
                                                )} placeholder={t('events.form.name')}/>
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className="fv-plugins-message-container">
                                                        <span role="alert">{formik.errors.name}</span>
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                        <div className={' form-group row'}>
                                            <div className={'col-lg-12 col-md-12 mt-2 mb-2 '}>
                                                <label className={'main-label'}>{t('events.form.email')}:</label>
                                                <input type={'email'}  {...formik.getFieldProps("email")}
                                                       placeholder={"jhon.doe@gmail.com"}
                                                       className={clsx("form-control main-input", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="fv-plugins-message-container">
                                                        <span role="alert">{formik.errors.email}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={' form-group row'}>
                                            <div className={'col-lg-12 col-md-12 mt-2 mb-2'}>
                                                <label className={'main-label'}>{t('events.form.nbOfPeople')}</label>

                                                <div className={'position-relative'}>
                                                    <span className={'cursor-pointer left-sign grey-color'}
                                                          onClick={handleDecreaseSeats}>-</span>
                                                    <input className={'form-control main-input text-center'}

                                                           type="text"
                                                           id="seats"
                                                           min="1"
                                                           max={event.seats_available}
                                                           value={seats}
                                                           onChange={(e) => {
                                                               const value = e.target.value.replace(/\D/g, ''); // Replace non-digit characters with empty string
                                                               if (value !== '' && parseInt(value, 10) >= 1 && parseInt(value, 10) <= event.seats_available) {
                                                                   setSeats(parseInt(value, 10));
                                                               } else {
                                                                   setSeats(1); // Set to default or last valid value
                                                               }
                                                           }}

                                                    />
                                                    <span className={'cursor-pointer right-sign grey-color'}
                                                          onClick={handleIncreaseSeats}>+</span>
                                                </div>


                                                {formik.touched.seat_number && formik.errors.seat_number && (
                                                    <div className="fv-plugins-message-container">
                                                        <span role="alert">{formik.errors.seat_number}</span>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <div className={'form-group row'}>
                                            <div className={'col-md-12 mb-2'}>
                                                <input
                                                    type="checkbox"
                                                    className="custom-checkbox"
                                                    {...formik.getFieldProps("agree")}
                                                />
                                                <span
                                                    className={`main-text opc ${currentLanguageCode === 'ar' && 'me-2'} `}>{t('events.form.agree')}</span>

                                            </div>

                                        </div>

                                        <div className={'row justify-content-center mt-lg-3 mt-3'}>
                                            <div className={'col-lg-12'}>
                                                <button className="btn btn-primary text-secondary-color w-100 "
                                                        disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                                    {!loading && (
                                                        <span className="indicator-label">
                                 {t('events.form.submit')}
                                </span>
                                                    )}
                                                    {loading && (
                                                        <span className="indicator-progress"
                                                              style={{display: "block"}}><span
                                                            className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            :
                            <div className={'p-4'}>
                                <div className={`row `}>
                                    <div className={'col-3'}>
                                        <img src={'/assets/images/events/calendar.png'} className={'w-100'} alt={'Calendar'}/>

                                    </div>
                                    <div className={'col-12'}>
                                        <div className={' pt-3'}>
                                            <div className={'main-h2-text primary'}>{t('events.fullyBooked')}</div>
                                        </div>
                                        <div className={'text-secondary-d sm-d'}>
                                            {moment(event.date).locale(currentLanguageCode).format("MMMM D, YYYY - h:mm A")}
                                        </div>
                                        <div className={'main-text opc mt-2 '}>
                                            {t('events.note')}
                                        </div>
                                    </div>
                                </div>
                            </div>


                    }

                </div>
            </div>


        </>
    );
};

export default BookForm;
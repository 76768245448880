import React, {FC} from "react";
import {toAbsoluteUrl} from "../../../helpers/AssetHelpers";


type Props = {
    event?: any
}
const EventDetails: FC<Props> = ({event}) => {

    return (
        <>
            <div className={'col-lg-6 col-md-12 col-12  mt-lg-5 mt-4'}>
                <div>
                    <div className={'main-h2-text primary'}>{event.title}
                    </div>
                </div>
                <div className={'mt-5'}>
                    <img src={toAbsoluteUrl(event.main_image.image)} alt={event.title} className={'w-100'}/>
                </div>
                <div className={'main-text opc mt-5 mb-5 '}
                     dangerouslySetInnerHTML={{__html: event.description}}/>
            </div>

        </>
    );
};

export default EventDetails;
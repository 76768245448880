import React from "react";
import H2Text from "../../../components/text/H2Text";
import {t} from "i18next";
import Cookies from "js-cookie";


type Props = {
    className?: string;
    title?: string;
    image?: any

};

const SchoolVisits: React.FC<Props> = ({className, title, image}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";

    return (
        <div className={'bg-secondary-color mt-lg-3 mt-5 pt-lg-3 mb-5 pb-5'}>
            <div className={'container'}>
                <div className={'row align-items-center justify-content-between'}>
                    <div
                        className={`col-md-12 ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}  mt-5`}>
                        <H2Text text={t('visit.school.title')}
                                className={'main-h2-text primary'}/>
                    </div>
                </div>
                <div className={'row align-items-center mt-3 mb-3'}>
                    <div className={'col-md-5'}>
                        <div><img src={'/assets/images/visit/school-visit-2.png'} alt={'School Visit'} className={'w-100'}/></div>
                    </div>
                    <div className={'col-md-1'}/>
                    <div className={'col-md-5 mt-lg-0 mt-3 '}>
                        <div>
                            <p className={'main-text'}
                               dangerouslySetInnerHTML={{__html: t('visit.school.description')}}/>
                            <div className={'mt-4'}>
                                <h6 className={'title-1 lt-sp-ms '}>{t('visit.guided.reservation')}</h6>
                                <div className={'d-lg-flex d-block justify-content-between mt-3'}>
                                    <div className={'mt-lg-0 mt-3'}>
                                        <div className={'sm-primary mb-2'}>{t('footer.email')}</div>
                                        <div className={'text-secondary-lg ps-lg-0 ps-3 fs-e'}>
                                            <a href={'mailto:info@bfaf.ae'}>info@bfaf.ae</a>
                                        </div>

                                    </div>
                                    <div className={'mt-lg-0 mt-3'}>
                                        <div className={'sm-primary mb-2'}>{t('moreInfo.phoneNumber')}</div>
                                        <div
                                            className={`text-secondary-lg  ps-lg-0 ps-3 en ${currentLanguageCode === 'en' ? '' : 'text-md-end text-end'}`}>
                                            +97126422338
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
export {SchoolVisits};

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import {App} from "../App";
import Home from "../pages/home/Home";
import ExhibitionIndex from "../pages/exhibition/ExhibitionIndex";
import About from "../pages/about/About";
import VisitIndex from "../pages/visit/VisitIndex";
import ExhibitionShow from "../pages/exhibition/ExhibitionShow";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import EventIndex from "../pages/event";
import EventShow from "../pages/event/Show";
import Book from "../pages/event/Book";
import { getExhibitions } from "../core/requests";
import translationsEn from "../locales/en/translation.json";
import translationsAr from "../locales/ar/translation.json";
import Cookies from "js-cookie";
import Newsletter from "../pages/newsletter/Newsletter";




/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        resources: {
            en: { translation: translationsEn },
            ar: { translation: translationsAr },
        },

        supportedLngs: ["en", "ar"],
        fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "htmlTag"],
            caches: ["cookie"],
        },
        preload: ["en", "ar" ],
    });

const AppRoutes: FC = () => {
    const [exhibitions, setExhibitions] = useState<any[]>([]);
    const [closedDays, setClosedDays] = useState<any>([]);
    const currentLanguageCode = Cookies.get("i18next") || "en";
    useEffect(() => {
        // Fetch exhibitions when the component mounts
        getExhibitions(currentLanguageCode).then(response => {

            console.log(response)
            setExhibitions(response.data.exhibitions);
            setClosedDays(response.data.closed_days)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log(closedDays)
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App  exhibitions={exhibitions} closedDays={closedDays}/>}>
                    <Route path='/:lang?' element={<Home exhibitions={exhibitions}/>} />

                    <Route path='/:lang?/exhibitions' element={<ExhibitionIndex exhibitions={exhibitions}/>} />
                    <Route path='/:lang?/exhibition/:slug' element={<ExhibitionShow />} />
                    <Route path='/:lang?/event-subscription' element={<Newsletter />} />

                    <Route path='/:lang?/whats-on' element={<EventIndex />} />
                    <Route path='/:lang?/event/:slug' element={<EventShow />} />
                    <Route path='/:lang?/event/:slug/book' element={<Book />} />

                    <Route path='/:lang?/about' element={<About />} />
                    <Route path='/:lang?/visit' element={<VisitIndex />} />
                    <Route path='/:lang?/terms-and-conditions' element={<Terms />} />
                    <Route path='/:lang?/privacy-policy' element={<Privacy />} />

                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export {AppRoutes}

import React, {FC, Suspense, useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Header from "./layout/base/Header";
import Footer from "./layout/base/Footer";
import ImageProtection from "./components/image/ImageProtection";
import 'moment/locale/ar';
import {PopUp} from "./pages/home/components/Modal";

interface Props {
    exhibitions: any[];
    closedDays ?: any
}
const App : FC<Props> = ({ exhibitions, closedDays })=> {
    const location = useLocation();
    const [dropdownItems, setDropdownItems] = useState<any>(exhibitions);

    useEffect(() => {
        if (dropdownItems.length === 0) {
            setDropdownItems(exhibitions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownItems]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Suspense>
            <Header showMenu={true} exhibitions={exhibitions} closedDays={closedDays}/>
            {/*<ImageProtection>*/}
                <Outlet />
            {/*</ImageProtection>*/}
            <Footer exhibitions={exhibitions}/>


        </Suspense>
    )
}

export {App}

import React, {FC} from "react";
import H2Text from "../../../components/text/H2Text";
import {CardVertical} from "../../../components/card/CardVertical";
import moment from "moment";
import Cookies from "js-cookie";
import {t} from "i18next";

type Props = {
    exhibitions?: any

}


const Exhibitions: FC<Props> = ({exhibitions}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <>
            <div className={' pt-lg-3 pb-3 mt-0 mt-3 pt-5 '}>
                <div className={'container  mt-5 pb-5'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-9'}>
                            <H2Text text={t('menu.exhibitions')} className={'main-h2-text text-center'}/>
                            <div className={'main-text text-center mt-2 mb-2'}>
                                {t('exhibition.description')}
                            </div>
                        </div>
                    </div>
                    <div className={'row justify-content-center pt-2'}>
                        {exhibitions && exhibitions.length > 0 && exhibitions.map((exhibition: any) => (
                            <div className={'col-lg-4 col-md-12 col-12 pt-3 pb-3 '} key={exhibition.id}>
                                {/*<Link to={`/exhibition/${exhibition.slug}`}>*/}
                                    <CardVertical h6Text={exhibition.name}
                                                  date={moment(exhibition.start_date).locale(currentLanguageCode).format("MMMM D[, ] YYYY") + t('to') + moment(exhibition.end_date).locale(currentLanguageCode).format("MMMM D[, ] YYYY")}
                                                  description={exhibition.title}
                                                  className={'bg-secondary-color'}
                                                  href={`/exhibition/${exhibition.slug}`}
                                                  image={exhibition.main_image}
                                                  action={t('buttons.discoverMore')}
                                                  dateClass={'text-secondary-d sm-d'}/>
                                {/*</Link>*/}

                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
export default Exhibitions;
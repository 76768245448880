import React, {FC} from "react";

type Props = {
    text?: string
    className?: string

}


const PText: FC<Props> = ({text, className}) => {
    return (
        <>
            <p className={className}>{text}</p>
        </>
    )
}
export default PText;
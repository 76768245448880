import React from "react";
import {toAbsoluteUrl} from "../../helpers/AssetHelpers";


type Props = {
    className?: string;
    title?: string;
    image?: any

};

const CardHero: React.FC<Props> = ({className, title, image}) => {

    return (
        <div className={`card-hero position-relative ${className}`}>
            <div className="overlay-container">
                <img className="d-block w-100 hero-img object-cover" src={`${toAbsoluteUrl(image)}`} alt="Hero"/>
                <div className="overlay o-100"></div>
            </div>
            {title &&
                <div className={'centered-text hero-title'}>
                    {title}
                </div>}

        </div>
    )
}
export {CardHero};

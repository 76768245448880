import React, {FC} from "react";
import {t} from "i18next";
import moment from "moment/moment";
import Cookies from "js-cookie";
import CarouselItems from "../../../components/carousel/CommonCarousel";


type Props = {
    event: any
    colClass: string
    padding: string
    showDate?: boolean

}
const EventGallery: FC<Props> = ({event, colClass, padding, showDate}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";

    return (
        <>
            <div className={`${colClass} col-md-12 col-12  mt-lg-2 mt-2 `}>

                <div className={'bg-secondary-color'}>
                    <div className={' ps-4 pe-4'}>
                        {/*<div className={''}>*/}
                        {/*    <div className={'main-h2-text primary'}>{t('events.pastEvent')}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {showDate &&
                            <div className={'text-secondary-d sm-d pt-4'}>
                                {moment(event.date).locale(currentLanguageCode).format("MMMM D, YYYY - h:mm A")}
                            </div>
                        }

                        <div className={'text-2-p f-semi-bold text-uppercase'}>
                            {t('events.highlights')}
                        </div>
                    </div>
                    <div className={'pb-4 p-lg-0'}>
                        <CarouselItems images={event.images}/>
                    </div>
                </div>

            </div>
        </>
    );
};

export default EventGallery;
import React from "react";
import Cookies from "js-cookie";
import {Link} from "react-router-dom";


type Props = {
    className?: string;
    h6Text?: string;
    description?: any
    date?: any
    author?: any
    href?: any
    image?: any
    action?: any
    dateClass?: any
    equalHeight?: any
};

const CardVertical: React.FC<Props> = ({
                                           equalHeight,
                                           className,
                                           h6Text,
                                           description,
                                           date,
                                           author,
                                           href,
                                           image,
                                           action,
                                           dateClass
                                       }) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <div
            className={`card-vertical p-3 ${equalHeight ? '' : 'card card-custom gutter-b example example-compact card-stretch'} ${className}`}>
            <div><Link to={href}>
                <img src={`${image}`} className={'w-100 h-300px object-cover'} alt={'Article'}/>
            </Link>
            </div>
            <div className={'card-block pt-4'}>
                <h6 className={'title-1 lt-sp-ms '}>{h6Text}</h6>
                <div className={''}>
                    <div className={'d-flex justify-content-between main-text mt-2 mb-2 '}>
                        <div>
                            {/*{dateClass && <div className={'sm-primary'}>Date</div> }*/}
                            <div className={dateClass}>{date}</div>
                        </div>

                        <div>{author}</div>
                    </div>
                    <div className={'main-text mb-4'}>{description}</div>
                    {action &&
                        <div className={'mb-4'}>
                            <Link to={href} className={'fs-10 lh-14 rd-sm '}>{`${action}`} <i className= {`bi bi-arrow-${currentLanguageCode === 'en' ? 'right' : 'left'}`} /> </Link>
                        </div>
                    }

                </div>

            </div>
        </div>
    )
}
export {CardVertical};

import React from "react";
import moment from "moment";
import Cookies from "js-cookie";
import {t} from "i18next";


type Props = {
    exhibition?: any

};

const HeaderExhibition: React.FC<Props> = ({exhibition}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <div className={'pt-lg-3 pt-5 pb-lg-0 pb-3'}>
            <div className={'container  mt-5 '}>
                <div className={'row align-items-center justify-content-center '}>
                    <div className={'col-md-12 text-center mt-lg-5 mt-4'}>
                        <div className={'main-h2-text primary'}>{exhibition.name}</div>
                    </div>

                    <div className={'col-md-12 mt-lg-3 mt-3 text-center'}>
                        {/*<div className={'sm-primary'}>Date</div>*/}
                        <div className={'text-secondary-d sm-d'}>
                            {moment(exhibition.start_date).locale(currentLanguageCode).format("MMMM D[, ] YYYY") + t('to') + moment(exhibition.end_date).locale(currentLanguageCode).format("MMMM D[, ] YYYY")}
                        </div>

                    </div>

                </div>
                <div className={'row justify-content-center mt-4'}>
                    <div className={'col-lg-9 col-md-12'}>
                        <div>
                            <img src={exhibition.image} className={'w-100 h-400px'} alt={exhibition.name}/>
                        </div>
                    </div>
                    <div className={'col-lg-5 mt-1 '}>
                        <div className={'main-text opc text-center '}
                             dangerouslySetInnerHTML={{__html: exhibition.caption}}/>

                    </div>
                </div>
                <div className={'row justify-content-center mt-3 mb-3'}>
                    <div className={'col-lg-12 col-md-12'}>
                        <div className={'main-text opc '} dangerouslySetInnerHTML={{__html: exhibition.description}}/>
                    </div>
                </div>
            </div>

        </div>
    )
}
export {HeaderExhibition};

import React, {FC} from "react";
import {Separator} from "../../components/separator/Separator ";
import NewsletterForm from "./components/NewsletterForm";
import {Link, NavLink, useLocation} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";

type Props = {
    exhibitions?: any
}
const Footer: FC<Props> = ({exhibitions}) => {
    const location = useLocation();
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";

    return (
        <div className={''}>
            <footer className={'bg-primary-color'}>
                <div className={'container'}>
                    <div className={'row justify-content-center mb-4'}>
                        <div className={'col-lg-5 col-md-12 col-12'}>
                            <div className={'subscribe-block'}>
                                <div
                                    className={`subscribe-text ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}   mt-3 pt-5`}
                                    dangerouslySetInnerHTML={{__html: t('footer.newsletter.title')}}/>
                            </div>
                        </div>
                        <div className={'col-lg-6 col-md-12 col-12'}>
                            <div className={'newsletter-form mt-lg-5 pt-lg-3  mt-2 pt-4'}>
                                <NewsletterForm/>
                            </div>
                        </div>
                    </div>
                </div>
                <Separator className={'d-lg-block d-none secondary-separator'}/>

                <div className={'container pt-5 '}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-10'}>
                            <div className={'d-lg-none d-block logo-container text-center'}>
                                <img src={'/assets/images/logos/logo-light.png'} alt={'Logo'}/>
                            </div>
                        </div>
                        <div className={'col-lg-6 col-md-12 col-6'}>
                            <div>
                                <div className={'d-lg-block d-none logo-container'}>
                                    <img src={'/assets/images/logos/logo-light.png'} alt={'Logo'}/>
                                </div>
                                <div className={'text-secondary-fs mt-5'}>
                                    /{t('footer.links')}
                                </div>
                                <ul className={'mt-3'}>
                                    <li><Link to={'/'} className={''}>{t('menu.home')}</Link></li>
                                    <li> <Link to={'/whats-on'}>{t('menu.whatsOn')}  </Link></li>
                                    <li><Link to={'/exhibitions'} className={''}>{t('menu.programs')}</Link></li>
                                    <li className={''}>
                                        <NavDropdown title={t('menu.exhibitions')} className={``}
                                                     id="navbarScrollingDropdown" drop="up">
                                            {exhibitions && exhibitions.length > 0 && exhibitions.map((item: any) => (
                                                <NavDropdown.Item key={item.id} href={`/exhibition/${item.slug}`}
                                                                  className={`${location.pathname.includes(item.slug) ? 'active' : ''}`}>
                                                    {item.name}
                                                </NavDropdown.Item>
                                            ))}
                                        </NavDropdown>
                                    </li>
                                    <li><Link to={'/about'} className={''}>{t('menu.about')}</Link></li>
                                    <li><Link to={'/visit'} className={''}>{t('menu.visit')}</Link></li>

                                </ul>
                            </div>
                        </div>
                        <div className={'col-lg-6 col-md-12 col-6'}>
                            <div className={'contact'}>
                                <div className={'text-secondary-fs mt-5'}>
                                    /{t('footer.contact')}
                                </div>
                                <div className={'row mt-3'}>
                                    <div className={'col-md-5 mt-lg-1 mt-2'}>
                                        <div className={'contact-title'}>{t('footer.address.title')}</div>
                                        <div className={'contact-description d-flex mt-2'}>
                                            <div className={`${currentLanguageCode === 'en' ? 'me-1' : 'ms-1'}`}><i
                                                className="bi bi-geo-alt-fill"/></div>
                                            <div
                                                dangerouslySetInnerHTML={{__html: 'BFAF<br/>' + t('home.location.location.description')}}/>

                                        </div>
                                    </div>
                                    <div className={'col-md-3 mt-lg-1 mt-4'}>
                                        <div className={'contact-title '}>{t('footer.phone.title')}</div>
                                        <div className={'contact-description d-flex mt-2'}>
                                            <div className={`${currentLanguageCode === 'en' ? 'me-1' : 'ms-1'}`}><i
                                                className="bi bi-telephone-fill"/></div>
                                            <div className={'en'}>+97126422338</div>
                                        </div>
                                        <div className={'contact-description d-flex mt-2'}>
                                            <div className={`${currentLanguageCode === 'en' ? 'me-1' : 'ms-1'}`}><i
                                                className="fab fa-whatsapp"/></div>
                                            <div className={'en'}>+971505683424</div>
                                        </div>
                                    </div>
                                    <div className={'col-md-4 mt-lg-1  mt-4'}>
                                        <div className={'contact-title'}>{t('footer.email')}</div>
                                        <div className={'contact-description d-flex mt-2'}>
                                            <div className={`${currentLanguageCode === 'en' ? 'me-1' : 'ms-1'}`}><i
                                                className="bi bi-envelope-fill"/></div>
                                            <div className={'text-lowercase'}><a
                                                href={'mailto:info@bfaf.ae'}>info@bfaf.ae</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'row mt-3'}>
                                    <ul className={'social-links pt-lg-3'}>
                                        <li><Link to={'https://www.instagram.com/bassamfreihaartfoundation/?img_index=1'}
                                               target={'_blank'}><i className="bi bi-instagram"/></Link></li>
                                        <li><Link
                                            to={'https://www.linkedin.com/company/101576792/admin/feed/posts/?feedType=following'}
                                            target={'_blank'}><i className="fab fa-linkedin-in"/></Link></li>
                                        <li><Link to={'https://www.facebook.com/profile.php?id=61556276798022'}
                                               target={'_blank'}><i className="fab fa-facebook-f"/></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator className={'secondary-separator'}/>
                <div className={'text-center mt-1 copy-right'}>
                    <p>© 2024 Bassam Freiha Art Foundation | <Link to={'/terms-and-conditions'}>Terms &
                                                                                                Conditions</Link> | <Link
                        to={'/privacy-policy'}>Privacy policy</Link></p>
                </div>
            </footer>
        </div>
    )
}
export default Footer;

import React from "react";
import {t} from "i18next";

const Vision: React.FC = () => {

    return (
        <>
            <div className={'bg-primary-color'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-md-5'}>
                            <div className={'title-1-secondary mt-5'}>
                                {t('about.founder.vision.title')}
                            </div>
                        </div>
                    </div>
                    <div className={'row pb-5'}>
                        <div className={'col-lg-5 col-md-12 col-12 mt-3'}>
                            <div className={'main-text light'}>
                                <p> {t('about.founder.vision.p1')}</p>

                                <p>  {t('about.founder.vision.p2')}</p>

                                <p>  {t('about.founder.vision.p3')}</p>

                                <p>  {t('about.founder.vision.p4')}</p>


                            </div>
                        </div>
                        <div className={'col-md-1'}/>
                        <div className={'col-lg-5 col-md-12 col-12 mt-3'}>
                            <div className={'main-text light'}>
                                <p> {t('about.founder.vision.p5')}</p>
                                <p>  {t('about.founder.vision.p6')}</p>

                                <p>  {t('about.founder.vision.p7')}</p>

                                <p>  {t('about.founder.vision.p8')}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export {Vision};

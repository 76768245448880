import React, {FC} from "react";
import {Link} from "react-router-dom";

type Props = {
    text?: any
    className?: string
    icon?: any
    href?: any
}


const LinkPrimary: FC<Props> = ({text, className, icon, href}) => {
    return (
        <>
            <Link to={href} className={className}>{text} {icon && <i className={icon}/>} </Link>
        </>
    )
}
export default LinkPrimary;
import React, {FC, useEffect, useState} from "react";
import {HeaderExhibition} from "./components/show/Hero";
import {GalleryExhibition} from "./components/show/GalleryExhibition";
import {useParams} from "react-router-dom";
import {getExhibition} from "../../core/requests";
import H2Text from "../../components/text/H2Text";
import moment from "moment";
import {SocialMeta} from "../../layout/base/components/social-meta";
import {t} from "i18next";
import Cookies from "js-cookie";


const ExhibitionShow: FC = () => {
    const param = useParams();
    const [exhibition, setExhibition] = useState<any>();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    useEffect(() => {
        getExhibition(param.slug, currentLanguageCode).then((response) => {
            setExhibition(response.data.data)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.slug]);


    return (
        <>
            <SocialMeta title={exhibition && exhibition.meta_title}
                        description={exhibition && exhibition.meta_description}/>

            {exhibition &&
                <HeaderExhibition exhibition={exhibition}/>
            }
            {exhibition && exhibition.images && exhibition.images.length > 0 &&
                <GalleryExhibition exhibitionImages={exhibition.images}/>
            }
            {exhibition && exhibition.info &&
                <div className={'pt-lg-3 pt-2 pb-lg-5 pb-5'}>
                    <div className={'container  mt-5  pb-2'}>
                        <div className={'row justify-content-center'}>
                            <H2Text text={t('moreInfo.title')} className={'main-h2-text text-center'}/>
                        </div>
                        <div className={'row justify-content-center mt-4'}>
                            <div
                                className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                                <div className={'title-1 mt-2 mb-2'}>{t('home.location.openingHours.title')} </div>
                                <div className={'text-secondary-d sm-d'}>
                                    {moment(exhibition.info.start_opening_hour, 'HH:mm').locale(currentLanguageCode).format('hA')} {t('to')} {moment(exhibition.info.end_opening_hour, 'HH:mm').locale(currentLanguageCode).format('hA')}
                                </div>

                            </div>
                            <div
                                className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                                <div className={'title-1 mt-2 mb-2'}>{t('moreInfo.phoneNumber')}</div>
                                <div
                                    className={`text-secondary-d sm-d en ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                                    +{exhibition.info.phone_number}
                                </div>

                            </div>

                            <div
                                className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                                <div className={'title-1 mt-2 mb-2'}>{t('footer.email')}</div>
                                <div className={'text-secondary-d sm-d fs-e'}>
                                    <a href={`mailto:${exhibition.info.email}`}>{exhibition.info.email}</a>
                                </div>

                            </div>
                            <div
                                className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                                <div className={'title-1 mt-2 mb-2'}>{t('footer.address.title')}</div>
                                <div className={'text-secondary-d sm-d'}>
                                    {exhibition.info.location}

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            }
        </>
    );
};

export default ExhibitionShow;
import React, {FC, useEffect, useState} from "react";
import {SocialMeta} from "../../layout/base/components/social-meta";
import Cookies from "js-cookie";
import {eventShow} from "./core/requests";
import {useParams} from "react-router-dom";
import EventDetails from "./components/EventDetails";
import Speaker from "./components/Speaker";
import moment from "moment";
import GoogleMapComponentModal from "./components/GoogleMapComponentModal";
import {PrimaryButton} from "../../components/button/PrimaryButton";
import {t} from "i18next";
import MoreInfo from "../exhibition/components/MoreInfo";
import EventGallery from "./components/EventGallery";


const EventShow: FC = () => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const param = useParams();
    const [event, setEvent] = useState<any>();
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleMarkerClick = () => {
        setShowModal(true);
    };

    useEffect(() => {
        eventShow(param.slug, currentLanguageCode).then((response) => {
            setEvent(response.data.data)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.slug]);

    return (
        <>
            {event &&
                <>
                    <SocialMeta title={event.meta_title} description={event.meta_description}/>
                    <div className={'pt-lg-5 mt-lg-3 pt-5 pb-lg-0 pb-3'}>
                        <div className={'container mt-5'}>
                            <div className={'row justify-content-between'}>
                                <EventDetails event={event}/>

                                <div className={'col-lg-5 col-md-12 col-12  mt-lg-5 mt-4'}>
                                    <div className={'bg-secondary-color'}>
                                        <div className={'row justify-content-center ps-4 pe-4 pt-4'}>

                                            <div
                                                className={`col-lg-6 col-md-12 mt-4 ${currentLanguageCode === 'en' ? 'text-start ' : 'text-end'}`}>
                                                <div className={'info-sm-primary mb-1'}>{t('events.date')}:</div>
                                                <div className={'text-secondary-lg lh-lg'}>
                                                    {moment(event.date).locale(currentLanguageCode).format("MMMM D, YYYY")}
                                                </div>

                                            </div>
                                            <div
                                                className={`col-lg-6 col-md-12 mt-4 ${currentLanguageCode === 'en' ? 'text-start ' : 'text-end'}`}>
                                                <div className={'info-sm-primary mb-1'}>{t('events.time')}:</div>
                                                <div
                                                    className={`text-secondary-lg lh-lg en ${currentLanguageCode === 'en' ? 'text-start ' : 'text-end'}`}>
                                                    {moment(event.date).locale(currentLanguageCode).format("h:mma")}
                                                </div>

                                            </div>

                                        </div>
                                        <div className={'row justify-content-center mt-4 pb-1  ps-4 pe-4 pt-4'}>
                                            <div
                                                className={`col-lg-12 col-md-12  ${currentLanguageCode === 'en' ? 'text-start ' : 'text-end'}`}>
                                                <div
                                                    className={'info-sm-primary mb-1'}> {t('home.location.location.title')}:
                                                </div>
                                                <div className={'text-secondary-lg lh-lg cursor-pointer'}
                                                     onClick={handleMarkerClick}>
                                                    {event.location}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className={'col-md-12 mt-4 mb-2  ps-4 pe-4'}>
                                                {moment(event.date).isAfter(moment(new Date()), 'day') &&
                                                    <PrimaryButton href={`/event/${event.slug}/book`}
                                                                   className={'btn btn-primary text-secondary-color w-100'}
                                                                   title={t('buttons.bookNow')}/>
                                                }
                                            </div>
                                        </div>
                                        {event.images.length > 0 &&
                                            <div className={'row'}>
                                                <EventGallery event={event} colClass={'col-lg-12'} padding={''}/>
                                            </div>
                                        }

                                        {event.speakers && event.speakers.length > 0 &&
                                            <Speaker speakers={event.speakers} event={event}/>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MoreInfo className={''}/>

                    <GoogleMapComponentModal location={event.google_location} isOpen={showModal}
                                             onClose={handleCloseModal}/>


                </>
            }
        </>
    );
};

export default EventShow;
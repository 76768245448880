import React, {FC, useRef} from "react";
import Slider from "react-slick";

const CarouselItems: FC = () => {
    const sliderRef = useRef<Slider>(null);
    const settings = {
        className: "center",
        centerMode: true, // Enable center mode
        centerPadding: "20%",
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    centerMode: true, // Enable center mode
                    centerPadding: '20%',
                    fade: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: true, // Enable center mode
                    centerPadding: '0', // Adjust center padding as needed
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: true, // Enable center mode
                    centerPadding: '0', // Adjust center padding as needed
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: true, // Enable center mode
                    centerPadding: '0', // Adjust center padding as needed
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true, // Enable center mode
                    centerPadding: '0', // Adjust center padding as needed
                },
            },
        ],


    };

    const images = [
        {id: '1', src: `/assets/images/architect/slider/1.jpg`},
        {id: '2', src: `/assets/images/architect/slider/2.jpg`},
        {id: '3', src: `/assets/images/architect/slider/3.jpg`},
        {id: '4', src: `/assets/images/architect/slider/4.jpg`},
        {id: '5', src: `/assets/images/architect/slider/5.jpg`},
        {id: '6', src: `/assets/images/architect/slider/6.jpg`},
        {id: '7', src: `/assets/images/architect/slider/7.jpg`}

    ];

    return (
        <div className={'architect bg-secondary-color'}>
            <div className={'container mt-lg-5 pt-lg-4 pt-0 mt-2'}>
                <div className={'row justify-content-center mt-lg-1 pt-lg-4 pt-5 pb-lg-4'}>
                    <div className={'col-lg-8 col-10 mb-5'}>

                        <Slider {...settings} className={' text-center'} ref={sliderRef}>
                            {images.map((item, index) => {
                                    return (
                                        <div key={item.id} className="">
                                            <img
                                                className="d-block w-100"
                                                src={item.src}
                                                alt="Slide"
                                            />

                                        </div>
                                    )
                                }
                            )}
                        </Slider>

                    </div>
                </div>

            </div>
        </div>


    )
}
export default CarouselItems;
import React from "react";
import H2Text from "../../../components/text/H2Text";
import CarouselItems from "./Carousel";
import {t} from "i18next";


const Architect: React.FC = () => {

    return (
        <>
            <div className={'mt-lg-3 pt-lg-3 mt-1 pt-1'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-9 mt-4 pb-4'}>
                            <H2Text text={t('about.architect.title')}
                                    className={'main-h2-text text-center'}/>
                        </div>
                    </div>
                    <div className={'row  align-items-center'}>
                        <div className={'col-xl-5 col-lg-5 col-md-12 col-12'}>
                            <div>
                                <img src={'/assets/images/architect/architect.jpg'} className={'w-100'} alt={t('about.architect.title')} />
                            </div>
                        </div>
                        <div className={'col-md-1'}/>

                        <div className="col-xl-5 col-lg-6 col-md-12 col-12 mt-lg-2  mt-2">
                            <div>

                                <div className={'main-text mt-3  mb-lg-5 mb-lg-5 mb-3'}>
                                    <p>
                                        {t('about.architect.p1')}                                    </p>
                                    <p>
                                        {t('about.architect.p2')}                                     </p>
                                    <p>
                                        {t('about.architect.p3')}                                     </p>
                                    <p>
                                        {t('about.architect.p4')}                                     </p>
                                    <p dangerouslySetInnerHTML={{__html: t('about.architect.p5')}}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <CarouselItems/>

        </>
    )
}
export {Architect};

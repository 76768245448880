import React, {FC, useEffect, useState} from "react";
import {CardHero} from "../../components/card/CardHero";
import {Story} from "./components/Story";
import {Mission} from "./components/Mission";
import {Founder} from "./components/Founder";
import {Curator} from "./components/Curator";
import {Architect} from "./components/Architect";
import {SocialMeta} from "../../layout/base/components/social-meta";
import {getPage} from "../../core/requests";
import Cookies from "js-cookie";


const About: FC = () => {
    const [page, setPage] = useState<any>();
    const currentLanguageCode = Cookies.get("i18next") || "en";

    useEffect(() => {
        getPage('about', currentLanguageCode).then((response) => {
            setPage(response.data.page);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <SocialMeta title={page ? page.meta_title : "Bassam Freiha Art Foundation"}
                        description={page ? page.meta_description : "Bassam Freiha Art Foundation"}/>
            <CardHero image={'/assets/images/about/about-banner.png'} className={'d-lg-block d-md-block d-none'}/>
            <Story/>
            <Mission/>
            <Founder/>
            <Curator/>
            <Architect/>
        </>
    );
};

export default About;
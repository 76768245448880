import React from "react";
import {t} from "i18next";


type Props = {
    className?: string;
    title?: string;
    image?: any

};

const HeroVisit: React.FC<Props> = ({className, title, image}) => {

    return (
        <div className={'pt-lg-3 pb-3 mt-0 mt-3 pt-5 '}>
            <div className={'container'}>
                <div className={'row align-items-center justify-content-between'}>
                    <div className={'col-lg-5 col-md-12 col-12 order-lg-1 order-2 mt-2 pt-2 mb-5'}>
                        <div className={'title-1 lh-34'} dangerouslySetInnerHTML={{__html: t('visit.title')}}/>
                        <div className={' '}>
                            <div className={'text-secondary-d sm-d pt-1'}>{t('visit.date')}</div>
                        </div>

                        <div className={'main-text mt-3'}>
                            {t('visit.description')}
                        </div>
                    </div>

                    <div className={'col-lg-6 col-md-12 col-12 mt-lg-5 pt-lg-5 mb-lg-5  mt-5 mb-3 order-lg-2 order-1'}>
                        <div className={'mt-3'}>
                            <img src={'/assets/images/visit/visit-hero.png'} alt={'BFAF'} className={'w-100'}/>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
export {HeroVisit};

import React, {FC, useEffect, useState} from "react";
import Cookies from "js-cookie";
import {getPage} from "../core/requests";
import {SocialMeta} from "../layout/base/components/social-meta";


const Privacy: FC = () => {
    const [page, setPage] = useState<any>();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    useEffect(() => {
        getPage('privacy-policy', currentLanguageCode).then((response) => {
            setPage(response.data.page);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SocialMeta title={page ? page.meta_title : "Bassam Freiha Art Foundation"}
                        description={page ? page.meta_description : "Bassam Freiha Art Foundation"}/>
            <div className={'container mt-5 pt-5 mb-5 pb-5'}>
                {page &&
                    <div className={'mt-3 main-text opc '}>
                        <h1 className="main-h2-text primary text-center" dir="ltr">{page.name}</h1>
                        <p dangerouslySetInnerHTML={{__html: page.description}}/>
                    </div>
                }
            </div>
        </>
    );
};

export default Privacy;
import axios from 'axios'
import {API_URL} from "../helpers/crud-helper/consts";


const EXHIBITION_URL = `${API_URL}/exhibition`
const CONTACT_US_URL = `${API_URL}/contact`
const CATEGORY_URL = `${API_URL}/category`
const SUBSCRIBE_URL = `${API_URL}/subscribe`
const HOME_URL = `${API_URL}/home`
const PAGE_URL = `${API_URL}/page`

const getExhibitions = (currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${EXHIBITION_URL}?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response)
}

const getEventsExhibitions = (currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${EXHIBITION_URL}/event?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response.data)
}
const getExhibition = async (slug: any, currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${EXHIBITION_URL}/${slug}?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response)
};

const home = async ( currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${HOME_URL}?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response)
};


const contactUs = (value : any): Promise<any> => {
    return axios
        .post(CONTACT_US_URL, value)
        .then((response) => response)
}

const getCategories = (currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${CATEGORY_URL}?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response)
}

const subscribe = (value : any): Promise<any> => {
    return axios
        .post(SUBSCRIBE_URL, value)
        .then((response) => response)
}

const subscribeEvent = (value : any): Promise<any> => {
    return axios
        .post(`${SUBSCRIBE_URL}-event`, value)
        .then((response) => response)
}

const getPage = async (slug: any , currentLanguageCode: any): Promise<any> => {
    return axios
        .get(`${PAGE_URL}/${slug}?language=${currentLanguageCode}`)
        .then((response) => response)
        .catch((response) => response)
};
export {getExhibitions, getExhibition,contactUs,getCategories, subscribe,subscribeEvent, home, getPage,getEventsExhibitions}

import React, {FC, useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {t} from "i18next";
import moment from "moment";
import {formatTimeRange} from "../../helpers/Utilities";
import Cookies from "js-cookie";
import {ClosedDay} from "../../helpers/crud-helper/models";

interface Props {
    isModalOpen: boolean;
    closeModal: () => void;
    closedDays ?: any
}
const PopUp:  FC<Props> = ({ isModalOpen, closeModal , closedDays }) => {


    return (
        <div>
            <Modal className="modal " size='lg' show={isModalOpen} onHide={closeModal} centered>
                <div className="text-center bg-white  main-text">
                    <Modal.Header className="border-bottom-0" closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-start ps-5 pe-5 pb-5 bg-white main-text g-l">

                            {closedDays.map((closedDay: any) => (
                                <div key={closedDay.id} dangerouslySetInnerHTML={{__html: closedDay.description}} />
                            ))}
                        </div>

                    </Modal.Body>
                </div>


            </Modal>
        </div>

    );
}
export {PopUp};
//
import React from 'react';
import {Modal} from "react-bootstrap";

type Props = {
    location: string;
    isOpen: boolean;
    onClose: () => void;
};

const GoogleMapComponentModal: React.FC<Props> = ({location, isOpen, onClose}) => {
    return (
        <div>
            <Modal className={''} centered={true} show={isOpen} onHide={onClose}>
                <Modal.Body>
                    <div className={'row justify-content-center mt-2  position-relative'}>
                        <div className={'col-md-11'}>

                            <div className={'iframe-container'}>
                                <div dangerouslySetInnerHTML={{__html: location}}/>
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>

        </div>
    );
};

export default GoogleMapComponentModal;



import React from 'react';
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';

const GoogleMapComponent: React.FC = () => {
    const mapContainerStyle = {
        width: '100%',
        height: '500px',
    };

    const center = {
        lat: 24.5332409,
        lng: 54.4167678
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyBacpsZPw5Y-e5qDHWJsnSkw9tqS9XeVWk">
            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10}>
                {/* Add markers or other map components here */}
                <Marker position={center}/>
            </GoogleMap>
        </LoadScript>
    );
};

export default GoogleMapComponent;
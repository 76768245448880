import React, {FC} from "react";
import H2Text from "../../../components/text/H2Text";
import Cookies from "js-cookie";
import {t} from "i18next";

type Props = {
    className?: string

}


const MoreInfo: FC<Props> = ({className}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <div className={className}>
            <div className={'pt-3 pb-lg-5 pb-5'}>
                <div className={'container mt-5 pb-2'}>
                    <div className={'row justify-content-center'}>
                        <H2Text text={t('moreInfo.title')} className={'main-h2-text text-center'}/>
                    </div>
                    <div className={'row justify-content-center mt-4'}>
                        <div
                            className={`col-md-3 ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'} `}>
                            <div className={'title-1 mt-2 mb-2'}>{t('home.location.openingHours.title')} </div>
                            <div className={'text-secondary-d sm-d'}>
                                {t('moreInfo.time')}
                            </div>

                        </div>
                        <div
                            className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                            <div className={'title-1 mt-2 mb-2'}>   {t('moreInfo.phoneNumber')}</div>
                            <div
                                className={`text-secondary-d sm-d en ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                                +97126422338
                            </div>

                        </div>

                        <div
                            className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                            <div className={'title-1 mt-2 mb-2'}>{t('footer.email')}</div>
                            <div className={'text-secondary-d sm-d fs-e'}>
                                <a href={'mailto:info@bfaf.ae'}>info@bfaf.ae</a>
                            </div>

                        </div>
                        <div
                            className={`col-md-3  ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'}`}>
                            <div className={'title-1 mt-2 mb-2'}>{t('footer.address.title')}</div>
                            <div className={'text-secondary-d sm-d'}
                                 dangerouslySetInnerHTML={{__html: t('home.location.location.description2')}}/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default MoreInfo;
import React from "react";
import H2Text from "../../../components/text/H2Text";
import {Separator} from "../../../components/separator/Separator ";
import GoogleMapComponent from "./GoogleMapComponent";
import {t} from "i18next";


type Props = {
    className?: string;
    title?: string;
    image?: any

};

const Map: React.FC<Props> = ({className, title, image}) => {

    return (
        <div className={'pt-3 pb-3 bg-secondary-color'}>
            <div className={'container mt-5 pb-5'}>
                <div className={'row align-items-center justify-content-between'}>
                    <div className={'col-md-6'}>
                        <div>
                            <GoogleMapComponent/>
                        </div>
                    </div>
                    <div className={'col-md-5 mt-lg-0 mt-3'}>
                        <div>
                            <H2Text text={t('visit.getting.title')} className={'main-h2-text primary'}/>
                            <div className={'row mt-3'}>
                                <div className={'col-lg-3 col-md-12 col-12 mt-1'}>
                                    <div className={'text-2-p'}>{t('visit.getting.address.title')}</div>
                                </div>
                                <div className={'col-lg-9 col-md-12 col-12 mt-1'}>
                                    <div className={'main-text'}>{t('visit.getting.address.description')}</div>
                                </div>
                            </div>
                            <Separator className={'primary-separator'}/>
                            <div className={'row'}>
                                <div className={'col-lg-3 col-md-12 col-12 mt-2'}>
                                    <div className={'text-2-p'}>{t('visit.getting.car.title')}</div>
                                </div>
                                <div className={'col-lg-9 col-md-12 col-12 mt-1'}>
                                    <div className={'main-text'}
                                         dangerouslySetInnerHTML={{__html: t('visit.getting.car.description')}}/>
                                    <br/>
                                    <div className={'main-text'}
                                         dangerouslySetInnerHTML={{__html: t('visit.getting.car.description2')}}/>
                                </div>
                            </div>
                            <Separator className={'primary-separator'}/>
                            <div className={'row'}>
                                <div className={'col-lg-3 col-md-12 col-12 mt-2'}>
                                    <div className={'text-2-p'}>{t('visit.getting.bus.title')}</div>
                                </div>
                                <div className={'col-lg-9 col-md-12 col-12 mt-1'}>
                                    <div className={'main-text'}
                                         dangerouslySetInnerHTML={{__html: t('visit.getting.bus.description')}}/>
                                </div>
                            </div>
                            <Separator className={'primary-separator'}/>
                            <div className={'row'}>
                                <div className={'col-lg-3 col-md-12 col-12 mt-2'}>
                                    <div className={'text-2-p'}>{t('visit.getting.parking.title')}</div>
                                </div>
                                <div className={'col-lg-9 col-md-12 col-12 mt-1'}>
                                    <div className={'main-text'}>{t('visit.getting.parking.description')}
                                    </div>
                                </div>
                            </div>
                            {/*<Separator className={'primary-separator'} />*/}
                            {/*<div className={'row'}>*/}
                            {/*    <div className={'col-lg-3 col-md-12 col-12 mt-2'}><div className={'text-2-p'}>Drop-off :</div></div>*/}
                            {/*    <div className={'col-lg-9 col-md-12 col-12 mt-1'}><div className={'main-text'}>N.AB Directions to the drop off I suppose, we drop a pin on the side of the building where the drop off is. </div></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
export {Map};

import React, {FC, useEffect, useState} from "react";
import {HeroVisit} from "./components/Hero";
import {Map} from "./components/Map";
import {GuidedTours} from "./components/GuidedTours";
import {SchoolVisits} from "./components/SchoolVisits";
import ContactForm from "./components/Contact";
import {getCategories, getPage} from "../../core/requests";
import {SocialMeta} from "../../layout/base/components/social-meta";
import Cookies from "js-cookie";


const VisitIndex: FC = () => {
    const [categories, setCategories] = useState<any>();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [page, setPage] = useState<any>();
    useEffect(() => {
        getCategories(currentLanguageCode).then((response) => {
            setCategories(response.data.data)
        });
        getPage('visit', currentLanguageCode).then((response) => {
            setPage(response.data.page);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <SocialMeta title={page ? page.meta_title : 'Plan Your Visit to Bassam Freiha Art Foundation (BFAF)\n'}
                        description={page ? page.meta_description : 'Plan your visit to Bassam Freiha Art Foundation (BFAF) in Abu Dhabi, UAE. Explore opening hours, location details, and guided tours information.'}/>
            <HeroVisit/>
            <Map/>
            <GuidedTours/>
            <SchoolVisits/>
            {categories && categories.length > 0 &&
                <ContactForm categories={categories}/>
            }
        </>
    );
};

export default VisitIndex;
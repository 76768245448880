import React from 'react';
import {Carousel} from 'react-bootstrap';
import H1Text from '../text/H1Text';
import PText from '../text/PText';
import {toAbsoluteUrl} from '../../helpers/AssetHelpers';

type Props = {
    sliderImages?: any;
};

const LandscapeCarousel: React.FC<Props> = ({sliderImages}) => {
    return (
        <div className={`home`}>
            <Carousel data-bs-theme="light">
                {sliderImages.map((item: any) => (
                    <Carousel.Item key={item.id}>
                        <div className="overlay-container">
                            <img className="d-block w-100 carousel-img" src={toAbsoluteUrl(item.image)}
                                 alt="Banner"/>
                            <div className="overlay"></div>
                        </div>
                        <Carousel.Caption>
                            <H1Text text={item.title} className={'main-h1-text fw-normal'}/>
                            <PText text={item.description} className={'main-p-text opc'}/>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export {LandscapeCarousel};

import React, {FC, useEffect, useState} from "react";
import {LandscapeCarousel} from "../../components/carousel/LandscapeCarousel";
import {AboutBlock} from "./components/About";
import {EventsBlock} from "./components/Events";
import {Separator} from "../../components/separator/Separator ";
import {LocationBlock} from "./components/LocationBlock";
import {home} from "../../core/requests";
import Cookies from "js-cookie";
import {SocialMeta} from "../../layout/base/components/social-meta";
import {PageContent} from "../../models/PageContent";
import {filterPageContentBySectionType} from "../../helpers/Utilities";

type Props = {
    exhibitions: any
}
const Home: FC<Props> = ({exhibitions}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [sliderImages, setSliderImages] = useState<any>([]);
    const [page, setPage] = useState<any>();
    const [pageContent, setPageContent] = useState<PageContent[]>([])


    useEffect(() => {
        home(currentLanguageCode).then((response) => {
            setSliderImages(response.data.slider);
            setPage(response.data.page);

            setPageContent(response.data.page_content)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SocialMeta title={page ? page.meta_title : "Bassam Freiha Art Foundation"}
                        description={page ? page.meta_description : "Bassam Freiha Art Foundation"}/>
            {sliderImages && sliderImages.length > 0 ? <LandscapeCarousel sliderImages={sliderImages}/> :
                <div className="white-bg d-flex justify-content-center align-items-center h-500px">
                </div>}
            <AboutBlock
                exhibition={exhibitions && exhibitions.length > 0 && exhibitions.filter((exhibition: any) => exhibition.is_main === 1)}
            pageContent={pageContent}/>
            <EventsBlock exhibitions={exhibitions && exhibitions.length > 0 && exhibitions}/>
            <Separator className={'primary-separator'}/>
            <LocationBlock/>
            {/*<Separator className={'primary-separator'} />*/}
            {/*<ArticlesBlock />*/}
            {/*<PopUp />*/}
        </>
    );
};

export default Home;

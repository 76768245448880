import React from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";

type Props = {};

const LocationBlock: React.FC<Props> = () => {
    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";

    return (
        <>
            <div className={'container pt-lg-3 pb-lg-3 pt-1'}>
                <div className={'row justify-content-center mt-lg-5 mt-4'}>
                    <div className={'col-lg-12 col-md-10 col-12'}>
                        <div className={'mb-3'}>
                            <img src={'/assets/images/home/location-1.png'} className={'w-100'} alt={'Location'}/>
                        </div>

                        <div className={'row justify-content-between pt-lg-1 pt-3 pb-lg-3 pb-4'}>
                            <div className={` pt-lg-2 ${currentLanguageCode === 'en' ? 'col-md-2' : 'col-md-3'}`}>


                                <div className={`pt-lg-3 pt-md-5 pt-2 `}>
                                    <div
                                        className={'title-1 pt-lg-0 pt-1 mb-3'}> {t('home.location.openingHours.title')}  </div>
                                    <div className={'text-secondary-d sm-d mb-4'}
                                         dangerouslySetInnerHTML={{__html: t('home.location.openingHours.description')}}/>


                                </div>
                            </div>
                            <div className={`pt-lg-2 ${currentLanguageCode === 'en' ? 'col-md-8' : 'col-md-6 '}`}>


                                <div
                                    className={`pt-lg-3 pt-md-5 pt-2  ${currentLanguageCode === 'en' ? 'pt-lg-3 pt-md-5 pt-2 text-lg-center text-start' : 'text-lg-center text-end'}`}>
                                    <div
                                        className={'title-1 pt-lg-0 pt-1 mb-3'}> {t('home.location.location.title')}  </div>
                                    <div className={'text-secondary-d sm-d mb-4'}
                                         dangerouslySetInnerHTML={{__html: t('home.location.location.description')}}/>


                                </div>
                            </div>

                            <div
                                className={` pt-lg-2  ${currentLanguageCode === 'en' ? 'col-md-2 ps-lg-5 ps-3 text-lg-end text-start' : 'col-md-3 pe-lg-5 pe-3 text-lg-start text-end'}`}>
                                <div className={`pt-lg-3 pt-md-5 pt-2 `}>
                                    <div
                                        className={'title-1 pt-lg-0 pt-1 mb-3'}>  {t('home.location.contact.title')}   </div>
                                    <div
                                        className={`en ${currentLanguageCode === 'en' ? 'text-lg-end text-start' : 'text-lg-start text-end'}`}>
                                        <div className={`text-secondary-d sm-d mb-4  `}
                                             dangerouslySetInnerHTML={{__html: '+97126422338 <br /> info@bfaf.ae'}}/>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export {LocationBlock};
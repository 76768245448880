import React, {FC, useEffect, useState} from "react";
import {SocialMeta} from "../../layout/base/components/social-meta";
import Cookies from "js-cookie";
import {eventShow} from "./core/requests";
import {useParams} from "react-router-dom";
import BookForm from "./components/BookForm";
import moment from "moment";
import EventGallery from "./components/EventGallery";
import EventDetails from "./components/EventDetails";

const Book: FC = () => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const param = useParams();
    const [event, setEvent] = useState<any>();
    useEffect(() => {
        eventShow(param.slug, currentLanguageCode).then((response) => {
            if (moment(response.data.data.date).isBefore(moment(new Date()), 'month')) {
                window.location.href = '/event/' + param.slug
            } else {
                setEvent(response.data.data)
            }

        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.slug]);


    return (
        <>
            {event &&
                <>
                    <SocialMeta title={event.meta_title + " | Book"} description={event.meta_description + "| Book"}/>
                    <div className={'pt-lg-5 mt-lg-3 pt-5 pb-lg-0 pb-3'}>
                        <div className={'container mt-5'}>
                            <div className={'row justify-content-between'}>
                                <EventDetails event={event}/>
                                {moment(event.date).isAfter(moment(new Date()), 'day') ?
                                    <BookForm event={event}/>
                                    :

                                    <div className={`col-lg-5 col-md-12 col-12  mt-lg-2 mt-2 `}>

                                        <div className={'bg-secondary-color pb-4'}>
                                            <EventGallery event={event} colClass={'col-lg-12'} padding={'p-3'}
                                                          showDate={true}/>
                                        </div>
                                    </div>


                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Book;
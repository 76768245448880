import React from "react";
import H2Text from "../../../components/text/H2Text";
import {CardVertical} from "../../../components/card/CardVertical";
import {PrimaryButton} from "../../../components/button/PrimaryButton";
import {t} from "i18next";


const Mission: React.FC = () => {

    return (
        <>
            <div className={'mt-lg-5 pt-lg-5 mt-2 pt-2 pb-lg-5 bg-secondary-color'}>
                <div className={'container'}>
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-9 mt-4'}>
                            <H2Text text={t('about.mission.title')}
                                    className={'main-h2-text text-center'}/>

                        </div>
                    </div>

                    <div className={'row justify-content-between pt-lg-4 pt-0'}>
                        <div className={'col-xl-4 col-lg-12 col-md-12 col-12 pt-lg-3 pt-1 pe-0 ps-0 text-center'}>
                            <CardVertical h6Text={t('about.mission.mission1.title')}
                                          equalHeight={true}
                                          description={t('about.mission.mission1.description')}
                                          className={''} href={'#'}
                                          image={'/assets/images/about/mission-1.png'}
                            />
                        </div>
                        <div className={'col-xl-4 col-lg-12 col-md-12 col-12  pt-lg-3 pt-1 pe-0 ps-0  text-center'}>
                            <CardVertical h6Text={t('about.mission.mission2.title')}
                                          equalHeight={true}
                                          description={t('about.mission.mission2.description')}
                                          className={''} href={'#'}
                                          image={'/assets/images/about/mission-2.png'}

                            />
                        </div>

                        <div className={'col-xl-4 col-lg-12 col-md-12 col-12  pt-lg-3 pt-1 pe-0 ps-0  text-center'}>
                            <CardVertical h6Text={t('about.mission.mission3.title')}
                                          equalHeight={true}
                                          description={t('about.mission.mission3.description')}
                                          className={''} href={'#'}
                                          image={'/assets/images/about/mission-3.png'}
                            />
                        </div>
                    </div>

                    <div className={'mt-lg-2 mt-4'}>
                        <div className={'row justify-content-center'}>
                            <div className={'col-lg-2 col-md-5 col-12'}>
                                <PrimaryButton className={'btn btn-primary text-secondary-color w-100 mb-lg-2 mb-5'}
                                               title={t('buttons.explore')} href={'/exhibitions'}/>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export {Mission};

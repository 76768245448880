import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Cookies from "js-cookie";
import moment from "moment/moment";
import {PrimaryButton} from "../../../components/button/PrimaryButton";
import {toAbsoluteUrl} from "../../../helpers/AssetHelpers";

type Props = {
    index?: number
    event?: any
    isPast?: boolean
    colClass ?: any
}
const EventCard: FC<Props> = ({event, index, isPast, colClass}) => {

    const {t} = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <div className={`${colClass } bg-secondary-color mb-4`}>
                <div className={'row mt-4 mb-4 pt-2 pb-2 ps-4 pe-2 align-items-center'}>
                    <div className={'col-lg-6 col-md-12 col-12'}>
                        <div className={''}>
                            <img src={toAbsoluteUrl(event.image.image)} className={'w-100'} alt={event.title}/>
                        </div>
                    </div>
                    <div className={'col-lg-6 col-md-12 col-12'}>
                        <div>
                            <div className={'title-1 lt-sp-ms mt-3'}>
                                {event.title}
                            </div>
                            <div className={'main-text lh-b mt-3'}>
                                {event.description.replace(/<\/?[^>]+(>|$)|&nbsp;/g, "").substring(0, 200)}...
                            </div>
                            <div className={'mt-3'}>
                                <div
                                    className="text-secondary-d sm-d mb-2">{moment(event.date).locale(currentLanguageCode).format("MMMM D, YYYY - h:mm A")}
                                </div>
                            </div>
                        </div>
                        <div className={'row mt-3'}>
                            <div className={'col-lg-5 col-10 mb-2'}>
                                <PrimaryButton href={`/event/${event.slug}`} className={'btn btn-primary inverse w-100'}
                                               title={t('buttons.readMore')}/>
                            </div>
                            {moment(event.date).isAfter(moment(new Date()), 'day') &&
                                <div className={'col-lg-5 col-10 mb-2'}>
                                    <PrimaryButton href={`/event/${event.slug}/book`}
                                                   className={'btn btn-primary text-secondary-color w-100'}
                                                   title={t('buttons.bookNow')}/>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

    );
};

export default EventCard;
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import H2Text from "../../../../components/text/H2Text";
import PhotoAlbum from "react-photo-album";
import {t} from "i18next";
import generatePhotos from "../../Photos";


type Props = {
    exhibitionImages?: any
};

const GalleryExhibition: React.FC<Props> = ({exhibitionImages}) => {
    const [visiblePhotos, setVisiblePhotos] = useState<number>(9);
    const [selectedImage, setSelectedImage] = useState<any | null>(null);

    const openModal = (index: any) => {

        setSelectedImage(index);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handleImageClick = (index: any) => {

        openModal(index);
    };
    const handleLoadMore = () => {
        setVisiblePhotos(visiblePhotos + 9);
    };

    const photos = generatePhotos(exhibitionImages);


    return (
        <div className={' mt-lg-5 pt-lg-5 mt-2 pt-2 pb-lg-3 bg-secondary-color'}>
            <div className={'container pb-5'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-9  mt-4 mb-4'}>
                        <H2Text text={t('exhibition.highlights')}
                                className={'main-h2-text text-center'}/>

                    </div>
                </div>


                <PhotoAlbum photos={photos.slice(0, visiblePhotos)} layout="rows" onClick={handleImageClick}/>
                {visiblePhotos < photos.length && (
                    <div className={'row justify-content-center'}>
                        <div className={'col-md-2'}>
                            <div className="text-center mt-lg-5 mt-5">
                                <button className={'btn btn-primary text-secondary-color w-100 mb-lg-2 mb-5 '}
                                        onClick={handleLoadMore}>{t('buttons.loadMore')}</button>
                            </div>
                        </div>

                    </div>
                )}
                <Modal className={'highlights'} show={selectedImage !== null} onHide={closeModal} centered>
                    {selectedImage && (
                        <div>
                            <div className={'big-image'}>
                                <img src={selectedImage.photo.src} alt="Exhibition Banner" className={' w-100'}/>
                            </div>
                            <div className={'main-text light mt-4'}
                                 dangerouslySetInnerHTML={{__html: selectedImage.photo.description}}/>

                            <div className={'close-modal-x cursor-pointer'}>
                                <div onClick={closeModal}><i className="bi bi-x-circle-fill"></i></div>
                            </div>
                        </div>
                    )}

                </Modal>
            </div>
        </div>
    )
}
export {GalleryExhibition};

import React from "react";
import {Separator} from "../../../components/separator/Separator ";
import H2Text from "../../../components/text/H2Text";
import {t} from "i18next";
import Cookies from "js-cookie";


const Story: React.FC = () => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    return (
        <>
            <div className={''}>
                <div className={'container'}>
                    <div className={'row justify-content-center pt-lg-3 mt-lg-0 mt-3 pt-5 pb-3'}>
                        <div className={'col-md-8 mt-5 pb-lg-5 pb-3'}>
                            <div className={'main-text  text-center'}>
                                {t('about.inspired')}
                            </div>
                            <div
                                className={`main-p-text signature mt-2 ${currentLanguageCode === 'en' ? 'text-end' : 'text-start'}`}>
                                {t('about.name')}
                            </div>
                        </div>

                        <Separator className={'primary-separator'}/>
                    </div>

                    <div className={'row justify-content-center  pt-lg-3 pt-1 pb-lg-3 pb-1'}>
                        <div className={'col-md-6  mt-lg-3 mt-0'}>
                            <div className={''}>
                                <H2Text text={t('about.foundation.title')}
                                        className={'main-h2-text text-center'}/>
                            </div>
                        </div>
                        <div className={'col-md-8 pt-lg-4 pt-2 mt-lg-3 mt-2'}>
                            <div>
                                <img src={'/assets/images/about/story.png'} className={'w-100'} alt={'Story'} />
                            </div>
                        </div>
                        <div className={'col-md-8 mt-4'}>
                            <div className={'text-center mt-4 mb-lg-0 mb-5'}>
                                <div className={'main-text'}>
                                    {t('about.foundation.description')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export {Story};

import React, {FC} from "react";
import H2Text from "../../../components/text/H2Text";
import {t} from "i18next";
import {toAbsoluteUrl} from "../../../helpers/AssetHelpers";
import Cookies from "js-cookie";

type Props = {
    speakers?: any
    event?: any
}
const Speaker: FC<Props> = ({speakers, event}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";

    return (
        <>
            <div className={'row  ps-4 pe-4 '}>
                <div className={'col-md-9  mt-4 mb-2 '}>
                    <H2Text text={t('events.speakers')}
                            className={'main-h2-text'}/>

                </div>
            </div>
            <div className={'row  ps-4 pe-4 pb-4'}>
                {speakers.map((speaker: any, index: any) => (
                    <div className={'col-lg-6 col-md-7 col-8'} key={speaker.id}>
                        <div>
                            <img src={toAbsoluteUrl(speaker.image)} alt={speaker.name} className={'h-250-cover w-100 bdr-img-white'}/>
                        </div>
                        <div
                            className={`speaker-name ${currentLanguageCode === 'en' ? 'text-start me-lg-4 pe-lg-4' : 'text-end ms-lg-3 ps-lg-3'} mt-2 mb-4 `}>
                            {speaker.name}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Speaker;
import React, {FC, useState} from "react";
import clsx from "clsx";
import {useFormik} from "formik";
import * as Yup from "yup";
import {subscribeEvent} from "../../core/requests";
import H2Text from "../../components/text/H2Text";
import PText from "../../components/text/PText";

const initialValues = {
    email: "",
    name: "",
    last_name: "",
    phone_number: "",
};
const newsletterSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required("First name is required"),


    last_name: Yup.string()
        .required("Last name is required"),

    phone_number: Yup.string()
        .matches(/^[0-9+]*$/, 'Phone number must contain only digits')
        .required("Phone number is required"),

});

const Newsletter: FC = () => {


    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: newsletterSchema,
        onSubmit: async (values, {setSubmitting}) => {

            setSubmitting(true);
            try {
                setLoading(true);

                const {data} = await subscribeEvent(values);

                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);
                        // resetForm()
                    }, 4500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            <div className={'container  pt-5 mb-lg-2'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-md-3  mt-5'}>
                        <img src={'/assets/images/logos/logo.png'} alt={'BFAF'} className={'w-100'}/>
                    </div>
                </div>

            </div>
            <div className={'container pt-2 mt-2 mb-lg-5 pb-2'}>
                <div className={'row justify-content-center'}>

                    {isActive ? (
                            <div className={'col-md-12  pt-5'}>
                                <div className={``}>
                                    <div className="text-center show  ">

                                        <H2Text text={'You\'ve successfully subscribed to our newsletter.'}
                                                className={'title-1 lt-sp-ms '}/>

                                        <H2Text
                                            text={'Thank you for joining the Bassam Freiha Art Foundation Community.'}
                                            className={'title-1 lt-sp-ms  mt-4'}/>
                                        <H2Text text={' We\'re excited to have you!'}
                                                className={'title-1 lt-sp-ms '}/>


                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <>
                            <div className={'col-md-12 pt-3'}>
                                <H2Text text={'Dive into the world of art with BFAF’s newsletter'}
                                        className={'main-h2-text text-center'}/>
                                <PText
                                    text={'Be the first to know about our latest exhibitions, exclusive tours, enriching educational programs, and exciting events.'}
                                    className={'subtitle mb-0 text-center'}/>
                                <PText text={'Stay connected and inspired - Subscribe Now!'}
                                       className={'subtitle text-center'}/>
                            </div>

                            <div className={'col-md-10 '}>
                                <form className="form justify-content-center w-100 " onSubmit={formik.handleSubmit}
                                      noValidate>
                                    <div className={' form-group row '}>
                                        <div className={'col-lg-6 col-md-12 mt-2 mb-2'}>
                                            <label className={'main-label'}>First Name:</label>
                                            <input type={'text'} {...formik.getFieldProps("name")} className={clsx(
                                                "form-control main-input",
                                                {"is-invalid": formik.touched.name && formik.errors.name,},
                                                {"is-valid": formik.touched.name && !formik.errors.name,}
                                            )} placeholder={'First Name'}/>
                                            {formik.touched.name && formik.errors.name && (
                                                <div className="fv-plugins-message-container">
                                                    <span role="alert">{formik.errors.name}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col-lg-6 col-md-12 mt-2 mb-2'}>
                                            <label className={'main-label'}>Last Name:</label>
                                            <input type={'text'} {...formik.getFieldProps("last_name")}
                                                   className={clsx(
                                                       "form-control main-input",
                                                       {"is-invalid": formik.touched.last_name && formik.errors.last_name,},
                                                       {"is-valid": formik.touched.last_name && !formik.errors.last_name,}
                                                   )} placeholder={'Last Name'}/>

                                            {formik.touched.last_name && formik.errors.last_name && (
                                                <div className="fv-plugins-message-container">
                                                    <span role="alert">{formik.errors.last_name}</span>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className={' form-group row'}>
                                        <div className={'col-lg-6 col-md-12 mt-2 mb-2 '}>
                                            <label className={'main-label'}>Email:</label>
                                            <input type={'email'}  {...formik.getFieldProps("email")}
                                                   placeholder={"jhon.doe@gmail.com"}
                                                   className={clsx("form-control main-input", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                                            {formik.touched.email && formik.errors.email && (
                                                <div className="fv-plugins-message-container">
                                                    <span role="alert">{formik.errors.email}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className={'col-lg-6 col-md-12 mt-2 mb-2'}>
                                            <label className={'main-label'}>Phone number:</label>
                                            <input type={'text'}  {...formik.getFieldProps("phone_number")}
                                                   className={clsx("form-control main-input", {"is-invalid": formik.touched.phone_number && formik.errors.phone_number,}, {"is-valid": formik.touched.phone_number && !formik.errors.phone_number,})}
                                                   placeholder={"+9714345677678"}/>

                                            {formik.touched.phone_number && formik.errors.phone_number && (
                                                <div className="fv-plugins-message-container">
                                                    <span role="alert">{formik.errors.phone_number}</span>
                                                </div>
                                            )}
                                        </div>

                                    </div>

                                    <div className={'row justify-content-center mt-lg-3 mt-3'}>
                                        <div className={'col-lg-3'}>
                                            <button className="btn btn-primary text-secondary-color w-100 "
                                                    disabled={formik.isSubmitting || !formik.isValid} type="submit">
                                                {!loading && (
                                                    <span className="indicator-label">
                                 Subscribe
                                </span>
                                                )}
                                                {loading && (
                                                    <span className="indicator-progress"
                                                          style={{display: "block"}}><span
                                                        className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>

                    }

                </div>
            </div>


        </>
    );
};

export default Newsletter;
import React, {FC, useState} from "react";
import * as Yup from "yup";
import {useFormik} from "formik";
import {clsx} from "clsx";
import {subscribe} from "../../../core/requests";
import {useTranslation} from "react-i18next";

const newsletterSchema = Yup.object().shape({
    email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email is required"),

    name: Yup.string()
        .required(),
});

const initialValues = {
    email: "",
    name: "",
};
const NewsletterForm: FC = () => {
    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const {t} = useTranslation();
    const formik = useFormik({
        initialValues,
        validationSchema: newsletterSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);

            try {
                setLoading(true);
                const {data} = await subscribe(values);

                if (data === 1) {
                    setLoading(false);
                    setIsActive(true);
                    formik.resetForm();
                    setTimeout(function () {
                        setIsActive(false);

                    }, 2500);
                }
            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <>
            {isActive && (
                <div className={``}>
                    <div className="alert alert-success alert-dismissible fade show  ">
                        Successfully Done!
                    </div>
                </div>
            )}
            <form className="form w-100 " onSubmit={formik.handleSubmit} noValidate>
                <div className={' form-group row '}>
                    <div className={'col-md-6  mb-lg-2 mb-1'}>

                        <div className={'newsletter-input-group'}>
                            <input type={'text'}  {...formik.getFieldProps("name")}
                                   placeholder={t('footer.newsletter.name')}
                                   className={clsx("form-control newsletter-input w-100", {"is-invalid": formik.touched.name && formik.errors.name,}, {"is-valid": formik.touched.name && !formik.errors.name,})}/>
                            <i className="bi bi-person"/>
                        </div>
                    </div>
                    <div className={'col-md-6 ps-lg-0  mb-lg-2 mb-1'}>

                        <div className={'newsletter-input-group'}>
                            <input type={'email'}  {...formik.getFieldProps("email")}
                                   placeholder={t('footer.newsletter.email')}
                                   className={clsx("form-control newsletter-input w-100", {"is-invalid": formik.touched.email && formik.errors.email,}, {"is-valid": formik.touched.email && !formik.errors.email,})}/>
                            <i className="bi bi-envelope"/>
                        </div>
                    </div>
                </div>
                <div className={'row  mt-lg-1 mt-1'}>
                    <div className={'col-lg-4 pt-1'}>
                        <button className="btn btn-secondary w-100" disabled={formik.isSubmitting || !formik.isValid}
                                type="submit">
                            {!loading && (
                                <span className="indicator-label"> {t('buttons.subscribe')}

                                </span>
                            )}
                            {loading && (<span className="indicator-progress" style={{display: "block"}}><span
                                className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default NewsletterForm;

import React, {useEffect, useState} from "react";
import {CardOne} from "../../../components/card/CardOne";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import {PageContent} from "../../../models/PageContent";
import {filterPageContentBySectionType} from "../../../helpers/Utilities";


type Props = {
    exhibition?: any;
    pageContent: PageContent[]
};

const AboutBlock: React.FC<Props> = ({exhibition, pageContent}) => {
    const currentLanguageCode = Cookies.get("i18next") || "en";
    const [aboutUsContent, setAboutUsContent] = useState<PageContent>()
    const [whatsOnContent, setWhatsOnContent] = useState<PageContent>()
    const [visitContent, setVisitContent] = useState<PageContent>()

    useEffect(() => {
        if (pageContent) {
            setAboutUsContent(filterPageContentBySectionType(pageContent, 'about_us'))
            setWhatsOnContent(filterPageContentBySectionType(pageContent, 'whats_on'))
            setVisitContent(filterPageContentBySectionType(pageContent, 'visit'))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageContent]);

    return (
        <>
            {
                pageContent &&
                <div className={'bg-secondary-color'}>
                    <div className={'container'}>
                        <div className={'row pt-5 pb-5 justify-content-between'}>
                            {
                                aboutUsContent ?
                                    <div
                                        className={`col-lg-1/5 col-md-12 col-12 ${currentLanguageCode === 'en' ? 'text-lg-start text-center' : 'text-lg-end text-center'} `}>
                                        <CardOne h6Text={aboutUsContent?.title}
                                                 description={aboutUsContent?.description}/>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                whatsOnContent ?
                                    <>
                                        <div className={'card-one text-center d-lg-none d-md-block d-block'}>
                                            <Link to={whatsOnContent?.link ?? '/exhibitions'}>
                                                <h6>{whatsOnContent?.title}</h6>
                                            </Link>
                                        </div>
                                        <div className={'col-lg-1/5 col-md-4 col-5'}>
                                            {exhibition &&
                                                <Link to={whatsOnContent?.link ?? '/exhibitions'}> <img
                                                    src={whatsOnContent?.image}
                                                    alt={`${whatsOnContent?.title} Image`}
                                                    className={'w-100 object-cover h-180px pt-lg-4 pt-2'}/></Link>
                                            }
                                        </div>
                                        <div className={'col-lg-1/5 col-md-8 col-7'}>
                                            {exhibition ?
                                                <div className={''}>
                                                    <Link to={whatsOnContent?.link ?? '/exhibitions'}> <CardOne
                                                        h6Text={whatsOnContent?.title} className={''}
                                                        classText={'d-lg-block d-md-none d-none'}
                                                        description={whatsOnContent?.description}/></Link>
                                                </div>
                                                :
                                                <Link to={whatsOnContent?.link ?? '/exhibitions'}> <CardOne
                                                    h6Text={whatsOnContent?.title} className={''}
                                                    classText={'d-lg-block d-md-none d-none'}
                                                    description={whatsOnContent?.description}/>
                                                </Link>
                                            }
                                        </div>
                                    </>
                                    :
                                    <></>
                            }

                            {
                                visitContent ?
                                    <>
                                        <div className={'card-one text-center d-lg-none d-md-block d-block'}>
                                            <Link to={visitContent?.link ?? '/visit'}><h6>{visitContent?.title}</h6>
                                            </Link>
                                        </div>
                                        <div className={'col-lg-1/5 col-md-4 col-5'}>
                                            <Link to={visitContent?.link ?? '/visit'}> <img src={visitContent?.image}
                                                                                            alt={`${visitContent?.title} Image`}
                                                                                            className={'w-100 pt-lg-4 pt-2  object-cover h-180px '}/></Link>
                                        </div>
                                        <div className={'col-lg-1/5 col-md-8 col-7'}>
                                            <Link to={visitContent?.link ?? '/visit'}> <CardOne
                                                h6Text={visitContent?.title}
                                                classText={'d-lg-block d-md-none d-none'}
                                                description={visitContent?.description}/>
                                            </Link>
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export {AboutBlock};
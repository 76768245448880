import React from "react";
import {Link} from "react-router-dom";


type Props = {
    className?: string;
    title?: string;
    href?: any
};

const PrimaryButton: React.FC<Props> = ({className, title, href}) => {

    return (
        <Link to={`${href}`} className={`${className}`}>{title}</Link>
    )
}
export {PrimaryButton};
